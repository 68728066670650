import {LoadingButton} from '@mui/lab';
import {Box, styled} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-responsive-modal';
// import 'react-responsive-modal/styles.css'
import {validateReferredCode} from 'src/Data/Client';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import CMFLogo from 'src/components/Logos/CMF';
import SpaceBr from 'src/components/Space/space';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import 'src/containers/OnboardingCredito/Modals/stylesModal.css';
import {CreateLatinData} from 'src/store/actions/KO.Actions';
import {setStep} from 'src/store/actions/Steps.Action';
import {
  SEONEvaluation,
  codeReferidos,
  logsOnboarding,
} from 'src/store/actions/requestCard.actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {openNotificationWithIcon} from 'src/utils/Functions';
import BackArrow from '../../assets/images/onboardingCredito/backArrow.svg';
import checkMark from '../../assets/images/onboardingCredito/checkMark.svg';
import canReturn from '../onboardingPhone/canReturn';
import SCREENS from './screens';
import {ORIGIN} from 'src/utils/Constants';
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '33%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '2rem',
  margin: 'auto',
  marginTop: '20vh',
  [BreakPointTheme.breakpoints.down('xxxl')]: {
    gap: '1rem',
    marginTop: '30vh',
  },
  [BreakPointTheme.breakpoints.down('xxl')]: {
    gap: '1rem',
    marginTop: '30vh',
  },
  [BreakPointTheme.breakpoints.down('xl')]: {
    gap: '1rem',
    marginTop: '30vh',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    gap: '1rem',
    marginTop: '30vh',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    gap: '1rem',
    marginTop: '30vh',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: 'auto',
    justifyContent: 'space-evenly',
    height: 'initial',
    gap: '10px',
    width: '90%',
  },
  // [BreakPointTheme.breakpoints.down('sm')]: {
  //   margin: 'auto',
  //   justifyContent: 'space-evenly',
  //   height: 'initial',
  //   gap: '1rem',
  //   width: '90%',
  // },
}));
const InfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: 15,
  paddingBottom: 15,
}));
const ContainerData = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '5px 60px',
  [BreakPointTheme.breakpoints.down('md')]: {
    padding: '5px 20px',
    width: '100%',
  },
}));
const ApplyButton = styled(LoadingButton)(() => ({
  height: ' 3.5rem',
  padding: 0,
  textAlign: 'center',
  fontSize: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  color: '#FFF',
  fontWeight: '300',
  /* Direct rgba values */
  background:
    'transparent linear-gradient(267deg, rgba(83, 166, 0, 1) 0%, rgba(57, 144, 1, 1) 100%) 0% 0% no-repeat padding-box !important',
  borderRadius: '5px 50px 50px 5px',
  opacity: 1,
  border: '2px solid var(--color-primary-main)',
  span: {
    all: 'unset',
    fontFamily: 'Red Hat Text !important',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  [BreakPointTheme.breakpoints.up('sm')]: {
    width: 120,
    minWidth: 120,
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    maxWidth: 120,
    width: '24vw',
  },
}));

const InputStyled = styled('input')(() => ({
  flex: 1,
  marginRight: 4,
  height: '100%',
  lineHeight: 1,
  padding: 3,
  fontSize: '1rem',
  borderRadius: '5px',
  border: 'solid 1px #B7B6B6',
}));

const ReferredInputWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '80%',
  alignSelf: 'center',
}));

const ReferredInputContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '80%',
  [BreakPointTheme.breakpoints.up('xl')]: {
    width: '100%',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    width: 'auto',
  },
}));
const InfoData = styled('p')(() => ({
  font: 'normal normal normal 1vw/30px Red Hat Text',
  textAlign: 'left',
  marginBottom: 8,
  [BreakPointTheme.breakpoints.down('xl')]: {
    fontSize: '1.4vw',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    fontSize: '1.9vw',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    fontSize: '2.5vw',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    font: 'normal normal normal 4vw/6.47vw  Red Hat Text',
  },
}));
const ErrorMessage = styled('h1')(() => ({
  all: 'unset',
  color: '#FF7B7B',
  fontSize: '1rem',
  fontWeight: 'bold',
  marginTop: 8,
  marginLeft: 40,
}));

const SuccessMessage = styled('h1')(() => ({
  all: 'unset',
  color: '#13a438',
  fontSize: '1rem',
  fontWeight: 'bold',
  marginTop: 8,
  marginLeft: 16,
}));

const SubTitle = styled('p')(() => ({
  textAlign: 'center',
  alignSelf: 'center',
  width: '100%',
  fontSize: '1rem',
  [BreakPointTheme.breakpoints.up('xl')]: {
    fontSize: '1.4rem',
  },
  [BreakPointTheme.breakpoints.down('xl')]: {
    fontSize: '0.875rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '85%',
  },
}));

const ReferredWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#F0F0F0',
  paddingTop: 32,
  paddingBottom: 16,
  alignSelf: 'center',
  borderRadius: '15px',
  width: '100%',
  [BreakPointTheme.breakpoints.up('xl')]: {
    marginTop: 0,
    paddingLeft: 64,
    paddingRight: 64,
    paddingBottom: 48,
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [BreakPointTheme.breakpoints.down('xl')]: {
    marginTop: 48,
    // paddingLeft: 32,
    // paddingRight: 32,
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    marginTop: 48,
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    marginTop: 'initial',
  },
}));
const ReferredContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
const IdentityConfirmed = props => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [referredCode, setReferredCode] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [sessionData, setSessionData] = useState('');
  const {
    onboardingId,
    origin,
    form,
    countriesBirthTranslate,
    nationalitiesTranslate,
    checkSeon,
    onboardingPrepago
  } = useSelector(state => state.RequestCardReducer);
  const hide = () => {
    setIsModalOpen(false);
    props.goToStep(SCREENS.ScanID);
  };
  useEffect(() => {
    window.seon.getBase64Session(function(data) {
      if (data) {
        setSessionData(data);
      } else {
        console.log('Failed to retrieve session data.');
      }
    });
  }, []);

  var nacimientoSEON = '';
  const renderConfirmationModal = () => {
    const nacionalidad = nationalitiesTranslate?.filter(function(value) {
      return value?.value?.toUpperCase() === form?.nationality?.toUpperCase();
    });
    const paisNacimiento = countriesBirthTranslate?.filter(function(value) {
      return (
        value?.value?.toUpperCase() === form?.countryOfBirth?.toUpperCase()
      );
    });
    const nacimiento = paisNacimiento[0]?.label;
    const nacionalidadData = nacionalidad[0]?.label;
    nacimientoSEON = nacimiento;
    const dateOfBirth = moment(form?.birthdate).format('DD-MMM-YYYY');
    let resNaci;
    if (JSON.stringify(nacionalidad) == '[]') {
      resNaci = false;
    }
    let res;
    if (JSON.stringify(paisNacimiento) == '[]') {
      res = false;
    }

    return (
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        classNames={{
          modal: 'identity__confirmed',
        }}
        center>
        <div
          role="button"
          className="cursor-pointer md:hidden absolute left-5 bg-[#F2F4F7] inline-block p-2 text-white rounded-lg">
          <img src={BackArrow} alt="Back arrow" />
        </div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px', // This sets the margin-top to 0 pixels
            '@media (min-width: 768px)': {
              // This is an example breakpoint for 'md:' in Tailwind CSS
              marginTop: '0px',
            },
            [BreakPointTheme.breakpoints.down('sm')]: {
              height: '83%',
              justifyContent: 'space-evenly',
            },
          }}
          style={{margin: '1rem 0 .3rem 0'}}>
          <div className="w-[90%] md:w-[90%] flex items-center justify-center flex-col mt-[0.3em]!">
            <img
              alt="Check mark"
              src={checkMark}
              style={{
                height: '59px',
                width: '59px',
              }}
            />
            <TitleResponsiveOnboarding
              minSize={30}
              maxSize={35}
              text="Confirma que tus datos son los correctos"
              color="#011E41"
              fontFamily="Red Hat Text"
            />
            <InfoWrapper>
              <ContainerData>
                <InfoData>
                  <strong>Nombres:</strong> {form?.firstName ?? ''}{' '}
                  {form?.secondName ?? ''}
                </InfoData>
                <InfoData>
                  <strong>Apellidos:</strong> {form?.lastName ?? ''}{' '}
                  {form?.secondLastName ?? ''}
                </InfoData>
                <InfoData>
                  <strong> Número de cédula:</strong> {form?.identity ?? ''}
                </InfoData>
                <InfoData>
                  <strong> Fecha de nacimiento:</strong>{' '}
                  {dateOfBirth && dateOfBirth.toUpperCase()}
                </InfoData>
                {res === false ? (
                  <div></div>
                ) : (
                  <InfoData>
                    <strong> País de nacimiento: </strong> {nacimiento ?? ''}
                  </InfoData>
                )}
                {resNaci === false ? (
                  <div></div>
                ) : (
                  <InfoData>
                    <strong> Nacionalidad: </strong> {nacionalidadData ?? ''}
                  </InfoData>
                )}
              </ContainerData>
            </InfoWrapper>
            <Box
              sx={{
                marginTop: '32px', // Equivalent to mt-[32px]
                width: '100%', // Equivalent to w-full

                // Media queries for different breakpoints
                [BreakPointTheme.breakpoints.up('md')]: {
                  marginTop: '1rem',
                },
              }}>
              <div className="flex justify-center gap-[14px]">
                <LoadingButton
                  loading={loadingVerify}
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    color: 'white', // Equivalent to text-white
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  data-testid="yes-confirmed-identity"
                  style={{background: 'var(--linear-bg)'}}
                  onClick={handleVerify}>
                  Si
                </LoadingButton>
                <LoadingButton
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    backgroundColor: 'white', // Equivalent to bg-white
                    border: '2px solid',
                    borderColor: 'var(--light-green)', // Make sure to define --light-green in your CSS
                    color: '#026E18', // Equivalent to the given hex color
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  onClick={() => {
                    hide();
                  }}>
                  No
                </LoadingButton>
              </div>
            </Box>
          </div>
        </Box>
        <p
          style={{
            marginTop: ' 20px',
            marginBottom: '20px',
            color: 'rgba(2, 110, 24, 1)',
            textAlign: ' center',
            letterSpacing: ' 0px',
            opacity: ' 1',
          }}>
          <strong>¿Necesitas asistencia en tu proceso? </strong>
          <br></br> contáctanos a servicioalcliente@cmf.com.pa
        </p>
      </Modal>
    );
  };
  const validateCode = async () => {
    setIsLoading(true);
    try {
      const {
        data: {jsonAnswer},
      } = await validateReferredCode(referredCode);
      const {
        Data: {Response, err},
      } = JSON.parse(jsonAnswer);
      if (err) {
        setIsValidCode(false);
        setShowError(true);
        dispatch(codeReferidos(null));
      } else {
        if (Response.length > 0) {
          setIsValidCode(true);
          setShowError(false);
          dispatch(codeReferidos(referredCode));
        } else {
          setIsValidCode(false);
          setShowError(true);
          dispatch(codeReferidos(null));
        }
      }
    } catch (error) {
      logsOnboarding(
        onboardingId,
        origin,
        'REFERRED_CODE',
        error,
        referredCode,
        true,
      );
    }
    setIsLoading(false);
  };
  const handleVerify = () => {
    if (form?.isDocumentNotExpired === false) {
      openNotificationWithIcon(
        'warning',
        'No puedes continuar con el proceso porque tu documento de identidad está expirado.',
      )
    } else {
      setLoadingVerify(true);
      if (origin !== ORIGIN.PREPAGO) {
        dispatch(CreateLatinData(onComplete.bind(this)));
      } else {
        onComplete(true);
      }
    }
  }

  const onComplete = success => {
    setLoadingVerify(false)
    if (success) {
      setIsModalOpen(false)
    } else {
      setLoadingVerify(false)
    }
  };
  const handleCheckTE = async () => {
    setLoading(true);
    if (checkSeon) {
      setLoading(false);
      dispatch(
        SEONEvaluation(onboardingId, origin, sessionData, nacimientoSEON),
      );
      dispatch(setStep(SCREENS.ApcTerms));
      props.goToStep(SCREENS.ApcTerms);
    } else {
      dispatch(setStep(SCREENS.ApcTerms));
      props.goToStep(SCREENS.ApcTerms);
    }
  };
  const textTDC = "Ahora puedes continuar con la solicitud de tu crédito CMF"
  const textPrepaid ="Ahora puedes continuar con la solicitud de tu Prepago"
  return (
    <>
      {renderConfirmationModal()}
      {/* <Container>
        <WrapperSide background="rgb(255, 255, 255)" />
        <Wrapper
          style={{
            flex: 1,
            height: '100%',
            justifyContent: 'center',
            background:
              'radial-gradient(circle at top left, #024114, transparent 30%),radial-gradient(circle at top right, #017617, transparent 30%),radial-gradient(circle at bottom left, #026D17, transparent 30%),radial-gradient(circle at bottom right, #026D17, transparent 30%),   linear-gradient(135deg, #026D17 0%, #0B7021 20%, #026C18 40%, #015517 60%, #024315 80%, #025918 100%)',
          }}>
          <center>
            <CMFLogo />
          </center>
          <ContainerInfo>
            <TitleResponsiveOnboarding
              minSize={28}
              maxSize={65}
              text="¡Listo! hemos verificado tu identidad"
              color="#fff"
              fontFamily="Red Hat Text"
            />
            <SubtitleResponsiveOnboarding
              minSize={22}
              maxSize={35}
              text={onboardingPrepago ? textPrepaid : textTDC}
              color="#fff"
              fontFamily="Red Hat Text"
              textAlign="center"
            />
            <SpaceBr minSize={545} />
            <ReferredContainer>
              <ReferredWrapper>
                <TitleResponsiveOnboarding
                  minSize={25}
                  maxSize={46}
                  text="¿Tienes un código de referido?"
                  color="rgba(1, 30, 65, 1)"
                  fontFamily="Red Hat Text"
                />
                <SubTitle style={{padding: 10, fontFamily: 'Red Hat Text'}}>
                  Ingresa el código que te compartió tu amigo o referido
                </SubTitle>
                <ReferredInputContainer>
                  <ReferredInputWrapper>
                    <InputStyled
                      data-testid="referral"
                      placeholder="Opcional"
                      onChange={e => setReferredCode(e.target.value)}
                    />
                    <ApplyButton
                      data-testid="btn-referral"
                      loading={isLoading}
                      disabled={isLoading}
                      onClick={() => validateCode()}>
                      Aceptar
                    </ApplyButton>
                  </ReferredInputWrapper>
                  {isValidCode ? (
                    <SuccessMessage
                      style={{
                        visibility: isValidCode ? 'visible' : 'hidden',
                      }}>
                      El código fue aplicado correctamente
                    </SuccessMessage>
                  ) : (
                    <ErrorMessage
                      style={{
                        visibility:
                          !isValidCode && showError ? 'visible' : 'hidden',
                      }}>
                      Error al aplicar el código
                    </ErrorMessage>
                  )}
                </ReferredInputContainer>
              </ReferredWrapper>
            </ReferredContainer>
          </ContainerInfo>
          <FooterOnboar
            back={props.canReturn ? canReturn(props, 3) : null}
            next={
              <ButtonNextOnboarding
                type="primary"
                data-testid="next-confirmed"
                loading={loading}
                style={{backgroundColor: '#fff', color: '#13A438'}}
                onClick={handleCheckTE}>
                Continuar
              </ButtonNextOnboarding>
            }
            screen="right"
          />
        </Wrapper>
      </Container> */}
    </>
  )
}

export default IdentityConfirmed
