import React from 'react';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';

export const Container = React.memo(styled('div')`
  display: flex;height: auto;min-height: 100vh;flex: 1;background: radial-gradient(circle at top left,#026d17,transparent 25%),radial-gradient(circle at top right,#026d17,transparent 30%),radial-gradient(circle at bottom left,#026d17,transparent 30%),radial-gradient(circle at bottom right,#026d17,transparent 30%),linear-gradient(
      135deg,#026d17 0%,#2b924e 10%,#a9f3e8 18%,#a6f2e7 25%,#4fe7cc 30%,#60d8bc 40%,#97e5d4 60%,#5fc6b1 80%,#28ac74 90%,#10863c 100%
    );background-blend-mode: overlay;@media (max-width: ${BreakPointTheme.breakpoints.values.sm1}px){background: linear-gradient(
      180deg,#a9f3e8 0%,#a6f2e7 20%,#4fe7cc 40%,#60d8bc 60%,#97e5d4 80%,#5fc6b1 90%,#3ec19b 100%
    );}`);export const DivWelcome = React.memo(styled.div`
  display: flex;flex-direction: column;justify-content: flex-start;flex: 1;align-items: center;@media (min-width: 600px){margin: 0;}@media (min-width: 1024px){margin: 1vh 0 0 0;}`);export const TitleSpan = React.memo(styled.div`
  font-size: 26px;color: #011e41;font-weight: 500;line-height: 52px;display: flex;flex-wrap: wrap;text-align: center;align-self: center;align-items: center;justify-self: center;justify-content: center;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){font-size: 22px;line-height: 22px;width: 100%;}@media (min-width: ${BreakPointTheme.breakpoints.values.sm}px){font-size: 35px;line-height: 32px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){font-size: 48px;line-height: 50px;width: 90%;}@media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px){font-size: 65px;line-height: 80px;}`);export const ImgLogo = React.memo(styled.img`
  margin: 0px 10px;width: 65px;
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm}px){width: 115px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){width: 125px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px){width: 170px;}`);export const SubtitleSpan = React.memo(styled.span`
  width: 95%;color: #011e41;font-size: 30px;display: flex;flex-direction: row;flex-wrap: wrap;text-align: center;align-self: center;justify-self: center;align-items: center;justify-content: center;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){font-size: 15px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){font-size: 17px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xl}px){font-size: 20px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px){font-size: 30px;}`);export const SubtitleSpanBold = React.memo(styled.span`
  font-weight: 700;color: #011e41;font-size: 30px;margin: 0px 10px; text-align:center; @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){margin: 0px 5px;font-size: 15px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){font-size: 17px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xl}px){font-size: 20px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px){font-size: 30px;}`);export const CardText = React.memo(styled.div`
  width: 80%;background: #026e18;border-radius: 10px;height: 100%;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){width: 90%;}@media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px){width: 80%;}@media (min-width: ${BreakPointTheme.breakpoints.values.md}px){width: 85%;}`);export const ListContainer = React.memo(styled.div`
  font-size: 20px;height: 100%;color: #fff;padding: 30px;line-height: 30px;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){font-size: 10px;line-height: 14px;font-weight: 100;}@media (max-width: ${BreakPointTheme.breakpoints.values.sm}px){padding: 10px;}@media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px){font-size: 14px;line-height: 16px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){font-size: 14px;line-height: 18px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xl}px){font-size: 20px;line-height: 26px;}`);export const TitleCardText = React.memo(styled.p`
  font-size: 25px;color: #fff;font-weight: 600;margin: 0;padding: 0px 0px 8px;${BreakPointTheme.breakpoints.up('xs')}{font-size: 14px;}${BreakPointTheme.breakpoints.up('sm1')}{font-size: 16px;}${BreakPointTheme.breakpoints.up('xl')}{font-size: 22px;}`);export const ImagePhone = React.memo(styled.img`
  width: auto;height: auto;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){height: 65px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xsm}px){height: 100px;}@media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px){height: 135px;}@media (min-width: ${BreakPointTheme.breakpoints.values.md}px){height: 155px;}@media (min-width: ${BreakPointTheme.breakpoints.values.lg}px){height: 165px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px){height: 250px;}`);export const BlockContainer = React.memo(styled.div`
  display: flex;flex-direction: row;width: 100%;@media (max-width: ${BreakPointTheme.breakpoints.values.md}px){flex-direction: column;}`);export const OnboardingContainer = React.memo(styled.div`
  display: flex;flex-direction: column
  width: 100%;justify-content : space-between;align-items: center;`);export const RequirementsDiv = React.memo(styled.div`
  color: #fff;font-size: 24px;width: 90%;background-color: #026e18;padding: 20px;border-radius: 10px;margin: auto;@media (max-width: ${BreakPointTheme.breakpoints.values.sm}px){padding: 10px;}@media (min-width: ${BreakPointTheme.breakpoints.values
      .xs}px) and (max-width: ${BreakPointTheme.breakpoints.values.sm1}px){font-size: 10px;line-height: 14px;font-weight: 100;padding: 7px;}@media (min-width: ${BreakPointTheme.breakpoints.values
      .sm1}px) and (max-width: ${BreakPointTheme.breakpoints.values.xl}px){font-size: 14px;line-height: 16px;padding: 15px;}@media (min-width: ${BreakPointTheme.breakpoints.values.xl}px){font-size: 20px;line-height: 26px;padding: 20px;}`);export const ButtonWelcome = React.memo(styled('button')`
  background-color: #fff;width: 60%;max-width: 240px;min-height: 76px;height: 76px;text-align: center;font-size: 25px;color: #026e18;font-weight: 500;border-radius: 50px;display: flex;justify-content: center;align-items: center;margin: 10px 0px;@media (min-width: ${BreakPointTheme.breakpoints.values.xs}px){width: 90%;min-height: 35px;height: 35px;font-size: 16px;}@media (min-width: ${BreakPointTheme.breakpoints.values.sm}px){width: 60%;min-height: 50px;height: 50px;font-size: 20px;}`);export const ContainerModal = React.memo(styled.div`
  font-family: 'Red Hat Text';background-color: #fff;position: absolute;display: flex;flex-direction: column;justify-content: center;align-items: center;top: 50%;left: 50%;transform: translate(-50%,-50%);overflow: auto;width: 90%;height: auto;border-radius: 15px;padding: 30px 0;@media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px){width: 60%;height: auto;}`);export const ContainerData = React.memo(styled.div`
  display: flex;flex-direction: column;font-family: 'Red Hat Text';text-align: center;align-items: center;justify-content: center;padding: 20px 10px;`);export const DataWebView = React.memo(styled.h1`
  font-family: 'Red Hat Text';color: #000;font-size: clamp(14px,1vw,20px);margin-bottom: 0;font-weight: 500;`);
