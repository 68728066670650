import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
  API_KEY,
  AccountApi,
  Cmf_Base,
  PointsApi,
  ProductCatalogue,
} from '../../Data/Api';
import { APP, IDENTITY_TYPES, PRODUCTS_TYPES } from '../../utils/Constants';
import {
  ConsoleHelper,
  dateMonthString,
  openNotificationWithIcon,
} from '../../utils/Functions';
import { setProductCoporate } from './actions';
export const getPlants = (data, onFinished) => {
  return async (dispatch, getState) => {
    dispatch({type: 'LOAD_AMOUNT', payload: true});
    try {
      const response = await axios.post(
        `${Cmf_Base}api/onboarding/credit/simulatorExtraCredit`,
        data,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      );
      if (response.status === 200 || response.status === 201) {
        dispatch({
          type: 'CALCULATOR',
          payload: response.data,
        });
        dispatch({type: 'LOAD_AMOUNT', payload: false});
        onFinished(true);
      } else if (response.status === 204) {
        onFinished(false);
        dispatch({type: 'LOAD_AMOUNT', payload: false});
        Swal.fire({
          title: 'Error!',
          text: 'El usuario no tiene producto de extra credito relacionado.',
          icon: 'error',
          confirmButtonColor: '#3DAE2B',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      onFinished(false);
      dispatch({type: 'LOAD_AMOUNT', payload: false});
      openNotificationWithIcon('error', error.message);
    } finally {
      onFinished(false);
      dispatch({type: 'LOAD_AMOUNT', payload: false});
    }
  };
};

export const extraCredit = () => {
  return async (dispatch, getState) => {
    try {
      const {AuthenticationReducer} = getState();
      const payload = {
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identification:
          AuthenticationReducer?.CurrentUser?.accountSF[0]
            ?.Documento_de_identidad__c,
      };
      const {data} = await axios.get(
        `${Cmf_Base}api/Customers/GetCustomerDetailbyTypeIdentityandIdentification2?typeIdentityId=${payload.typeIdentityId}&identification=${payload.identification}`,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      );
      if (
        data.complete &&
        data.jsonAnswer &&
        Array.isArray(data.jsonAnswer.customerFinancialProposalsDTO) &&
        data.jsonAnswer.customerFinancialProposalsDTO.length > 0
      ) {
        dispatch({
          type: 'EXTRA_CREDIT_SUCCESS',
          payload: data.jsonAnswer.customerFinancialProposalsDTO,
        });
      } else {
        dispatch({
          type: 'EXTRA_CREDIT_SUCCESS',
          payload: [],
        });
      }
      // if (
      //   Array.isArray(data.customerFinancialProposalsDTO) &&
      //   data.customerFinancialProposalsDTO.length > 0
      // ) {
      //   dispatch({
      //     type: 'EXTRA_CREDIT_SUCCESS',
      //     payload: data.customerFinancialProposalsDTO,
      //   })
      // } else {
      //   dispatch({
      //     type: 'EXTRA_CREDIT_SUCCESS',
      //     payload: [],
      //   })
      // }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Oops algo ha salido mal, por favor intente de nuevo.',
        icon: 'error',
        confirmButtonColor: '#3DAE2B',
        confirmButtonText: 'Ok',
      });
    }
  };
};

export const ActiveProduct = onSuccess => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    onSuccess(false);
    const token = CurrentUser?.tokenCMF;
    try {
      dispatch({type: 'ACTIVEPRODUCT_SHOW'});
      const Response = await axios.get(
        `${Cmf_Base}api/Customers/GetCustomerProductDetailbyCustomerId?customerId=${CurrentUser.customerId}&getAll=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {data} = Response;

      let filterData = data.filter(
        product => product.productTypeId !== PRODUCTS_TYPES.REMESA,
      );

      let validProduct = filterData.filter(
        product =>
          product?.isRenovated === false &&
          product?.renovatedBy === null &&
          product?.status === true &&
          product?.cycle !== null,
      );
      dispatch({type: 'LIST_CUSTOMERS_PRODUCTS', payload: validProduct});
      if (!!validProduct?.length) {
        const nonExistePrp = validProduct.filter(item => !item.isCorporate);
        const ExisteCorpo = validProduct.filter(item => item.isCorporate);
        if (nonExistePrp.length > 0) {
          dispatch({type: 'SELECTED_PRODUCT', payload: nonExistePrp[0]});
        } else if (ExisteCorpo.length > 0) {
          dispatch(setProductCoporate(ExisteCorpo[0]));
          dispatch({type: 'SELECTED_PRODUCT', payload: ExisteCorpo[0]});
        }
        dispatch({type: 'LIST_ACTIVE_PRODUCT', payload: validProduct});
      }
    } catch (error) {
      onSuccess(true);
    } finally {
      dispatch({type: 'ACTIVEPRODUCT_HIDE'});
    }
  };
};

export const productCatalogue = () => {
  return (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({type: 'PRODUCTCATALOGUE_SHOW'});
    axios
      .get(`${Cmf_Base}${ProductCatalogue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(Response => {
        const {data, status} = Response;
        if ((data, status)) {
          dispatch({type: 'PRODUCTCATALOGUE_SUCCESS', payload: data});
        }
      })
      .catch(error =>
        dispatch({type: 'PRODUCTCATALOGUE_ERROR', payload: error}),
      )
      .finally(() => dispatch({type: 'PRODUCTCATALOGUE_HIDE'}));
  };
};

export const generalAccountStatement = index => {
  return (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {products} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({type: 'GENERAL_ACCOUNT_STATEMENT_SHOW'});
    dispatch({type: 'GENERAL_ACCOUNT_STATEMENT_CLEAN'});
    if (products.length > 0) {
      let productAccount = encodeURIComponent(products[index]?.productAccount);
      axios
        .get(
          `${Cmf_Base}${AccountApi}?anyo=0&Mes=0&Cuenta=${productAccount}&CreatedBy=${products[index].customerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(Response => {
          const {data, status, statusText} = Response;
          if (status && status === 200 && data) {
            dispatch({
              type: 'GENERAL_ACCOUNT_STATEMENT_SUCCESS',
              payload: data,
            });
          } else {
            message.error(statusText);
          }
        })
        .catch(error =>
          dispatch({type: 'GENERAL_ACCOUNT_STATEMENT_ERROR', payload: error}),
        )
        .finally(() => dispatch({type: 'GENERAL_ACCOUNT_STATEMENT_HIDE'}));
    } else {
      dispatch({type: 'GENERAL_ACCOUNT_STATEMENT_HIDE'});
    }
  };
};
export const generalAccountStatementvtc = () => {
  return async (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {selectedProduct} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    dispatch({type: 'GENERALVTC_ACCOUNT_STATEMENT_SHOW'});
    dispatch({type: 'GENERALVTC_ACCOUNT_STATEMENT_CLEAN'});

    try {
      const response = await axios.get(
        `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
          selectedProduct?.productAccount,
        )}&CreatedBy=${CurrentUser.accountSF[0].Documento_de_identidad__c}`,
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
          },
        },
      );
      dispatch({
        type: 'GENERALVTC_ACCOUNT_STATEMENT_SUCCESS',
        payload: JSON.parse(response?.data.jsonAnswer),
      });
    } catch (error) {
      dispatch({type: 'LOAD_V', payload: false});
    } finally {
      dispatch({type: 'LOAD_V', payload: false});
    }
  };
};

export const estadoCuentaMes = () => {
  return (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {products, generals} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    let cycle = generals[0]?.cycle;
    let now = moment().format('DD-MMM-YYYY');
    let ciclo = moment(dateMonthString(cycle)).format('DD-MMM-YYYY');
    const resultado = moment(dateMonthString(cycle))
      .subtract(ciclo >= now ? 1 : 0, 'months')
      .format('DD-MMM-YYYY');
    const Mes = moment(resultado, 'DD-MMM-YYYY').format('M');
    const Anyo = moment(resultado, 'DD-MMM-YYYY').format('Y');
    dispatch({type: 'ESTADOCUENTA_STATUS_SHOW'});
    let productAccount = encodeURIComponent(products[0]?.productAccount);
    axios
      .get(
        `${Cmf_Base}${AccountApi}?anyo=${Anyo}&Mes=${Mes}&Cuenta=${productAccount}&CreatedBy=9e5ad463-8172-4710-adc0-473930a81f3f`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(Response => {
        const {data, status} = Response;
        if (status && status === 200 && data) {
          dispatch({type: 'ESTADOCUENTA_STATUS_SUCCESS', payload: data});
        } else {
          ConsoleHelper('No hay datos disponibles');
        }
      })
      .catch(error =>
        dispatch({type: 'ESTADOCUENTA_STATUS_ERROR', payload: error}),
      )
      .finally(() => dispatch({type: 'ESTADOCUENTA_STATUS_HIDE'}));
  };
};

export const sendInsuranceActivate = onSuccess => {
  return async (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {listActiveProduct} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({type: 'ACTIVA_INSURANCE_SHOW'});
    try {
      const response = await axios.post(
        APP.cmf02.endpoints.registerComplete,
        {
          CustomerProductId: listActiveProduct[0]?.customerProductId,
          RecordType: 1,
          PlanCode: 4,
          CoverageType: 'MO',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {data} = response;
      if (data.Complete === true) {
        onSuccess(true);
      } else {
        NotificationManager.error(
          data?.ProblemPublic
            ? data?.ProblemPublic
            : 'Ha ocurrido un error al enviar la solicitud',
        );
      }
    } catch (error) {
      NotificationManager.error(
        error?.response?.data?.problemPublic
          ? error?.response?.data?.problemPublic
          : 'Ha ocurrido un error al enviar la solicitud',
      );
    } finally {
      dispatch({type: 'ACTIVA_INSURANCE_HIDE'});
    }
  };
};
export const verifyInsuranceActivate = () => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;

    const identification =
      AuthenticationReducer?.CurrentUser?.accountSF[0]
        ?.Documento_de_identidad__c;
    try {
      const response = await axios.get(
        `${APP.cmf02.endpoints.apiChubb}/${identification}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {data, status} = response;
      if (data && status === 200) {
        dispatch({
          type: 'ISVERIFY_INSURANCE_SUCCESS',
          payload: true,
        });
      } else {
        dispatch({
          type: 'ISVERIFY_INSURANCE_SUCCESS',
          payload: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: '¡Error!',
        text: error?.response?.data?.problemPublic
          ? error?.response?.data?.problemPublic
          : 'Ha ocurrido un error al enviar la solicitud',
        icon: 'error',
        confirmButtonColor: '#3DAE2B',
        confirmButtonText: 'Ok',
      });
    }
  };
};
export const getStatusChubb = () => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    const identification =
      AuthenticationReducer?.CurrentUser?.accountSF[0]
        ?.Documento_de_identidad__c;
    try {
      const response = await axios.get(
        `${Cmf_Base}api/chubb/client/${identification}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {data, status} = response;
      if (data && status === 200) {
        const {data} = await response;
        dispatch({type: 'GET_INFO_INSURANSE', payload: data});
      } else {
        dispatch({
          type: 'ISVERIFY_INSURANCE_SUCCESS',
          payload: false,
        });
      }
    } catch (error) {
      dispatch({type: 'INSURANSE_ERROR', payload: error});
    } finally {
      dispatch({type: 'INSURANSE_LOAD_HIDE', payload: false});
    }
  };
};
