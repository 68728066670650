import styled from '@emotion/styled'
import { LoadingButton } from '@mui/lab'
import { createTheme } from '@mui/material'
import React from 'react'
import { BreakPointTheme } from 'src/theme/breakpoints'
const theme = createTheme()
const ButtonBack = styled(LoadingButton)(() => ({
  height: '7vh',
  width: '274px',
  font: 'normal normal normal 25px/26px Nunito Sans',
  fontSize: '25px',
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '244px',
    height: '3.5rem',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '200px',
    height: '3.5rem',
    fontSize: '1rem',
  },
  [theme.breakpoints.down('lg')]: {
    width: '200px',
    height: '56px',
    fontSize: '20px',
  },
  [theme.breakpoints.down('md')]: {
    width: '22vw',
    height: '60px',
    fontSize: '1.2rem',
    marginBottom: '2rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
    height: '8vh',
    maxHeight: '60px',
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    height: '3.5rem',
  },
}))
const canReturn = (props, step) => {
  const previousStep = () => {
    props.goToStep(step)
  }
  return (
    <ButtonBack
      loading={false}
      disabled={false}
      onClick={previousStep.bind(this)}>
      Volver
    </ButtonBack>
  )
}
export default canReturn
