import React, {forwardRef, useState, useCallback, memo} from 'react';
import ModalCommon from '../../../components/Modal/ModalCommon';

const ModalPersonalData = memo(
  forwardRef(({onConfirm, onClose = () => {}}, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const show = useCallback(() => {
      setIsModalOpen(true);
    }, []);

    const hide = useCallback(() => {
      onClose();
      setIsModalOpen(false);
    }, [onClose]);

    const onCloseModal = useCallback(() => {
      setIsModalOpen(false);
    }, []);

    return (
      <ModalCommon
        isOpen={isModalOpen}
        onHide={onCloseModal}
        onShow={show}
        ref={ref}
        modalClassName={{
          overlay: 'bg-[rgba(0,0,0,0.8)]',
          modal:
            'border border-2 border-gray-300 relative w-full md:w-[70%] lg:w-[90%] h-full md:h-[50%] lg:h-[636px] m-0',
          closeButton: 'hidden',
        }}
        bodyClassName="h-[60%] md:h-full lg:h-full flex flex-col items-center justify-center ">
        <div className="w-[90%] md:w-[80%] flex items-center justify-center flex-col ">
          <div className="flex items-center justify-center h-[auto] w-full">
            <h1 className="text-[#011E41] font-semibold text-[28px] md:text-[35px] lg:text-[45px] text-center">
              Datos de ciudadanía
            </h1>
          </div>

          <p className="text-black text-lg md:text-[30px] text-center leading-[25px] md:leading-[40px] mt-[18px] md:mt-[39px] lg:px-6">
            ¿Eres panameño o extranjero con residencia permanente en Panamá?
          </p>

          <div className="w-full mt-[42px]">
            <div className="flex justify-center gap-5">
              <button
                style={{background: 'var(--linear-bg)'}}
                data-testid="btn-yes-modal"
                className=" text-white text-xl md:text-[25px] rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[50%]  lg:w-[30%]"
                onClick={() => {
                  hide();
                  onConfirm();
                }}>
                Si
              </button>
              <button
                className="bg-white border-2 border-[var(--light-green)] text-xl md:text-[25px] text-[#026E18] rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[50%]  lg:w-[30%]"
                onClick={hide}>
                No
              </button>
            </div>
          </div>
        </div>
      </ModalCommon>
    );
  }),
);

export default ModalPersonalData;
