import family from 'src/assets/images/onboardingCredito/chubb/familyChubb.png'
import money from 'src/assets/images/onboardingCredito/chubb/moneyChubb.png'
import person from 'src/assets/images/onboardingCredito/chubb/personChubb.png'
import phone from 'src/assets/images/onboardingCredito/chubb/phoneChubb.png'
import style from './ChubbScreenBenefits.module.css'

const BENEFITS_DETAILS = [
    {
        id: 1,
        img: family,
        imgClasses: 'h-[70px]',
        title: 'No dejes desamparada a tu familia',
        subTitle:
            'Te otorgamos hasta $7,800 para ayudar a cubrir cualquier necesidad si falleces accidentalmente.',
    },
    {
        id: 2,
        img: phone,
        imgClasses: 'h-[70px]',
        title: 'Los accidentes suceden cuando menos los esperas',
        subTitle:
            'Recibe  $780 de reembolso por tus gastos medicos por accidente.',
    },
    {
        id: 3,
        img: person,
        imgClasses: 'h-[55px]',
        title: 'Servicio de Telemedicina',
        subTitle:
            'Asistencia médica telefónica 24/7 donde te ayudamos a evaluar tu estado de salud actual.',
    },
    {
        id: 4,
        img: money,
        imgClasses: 'h-[55px] w-[55px]',
        title: 'Pago automático',
        subTitle: 'Se carga automáticamente a tu tarjeta de crédito CMF.',
    },
];

/**
 * BenefitComponent displays a single benefit with an image and description.
 * 
 * This component renders a single benefit item with an image and its corresponding description.
 * 
 * @component
 * @param {string} data.img - URL of the image representing the benefit.
 * @param {string} data.title - Title of the benefit.
 * @param {string} data.subTitle - Subtitle or description of the benefit.
 * @example
 * return (
 *   <BenefitComponent data={benefitData} />
 * )
 */
const BenefitComponent = ({ data }) => {
    return (
        <div>
            <div className={style.imageContainer}>
                <img src={data.img} alt={`title: ${data.title}`} />
            </div>
            <div className={style.textContainer}>
                <span>{data.title}</span>
                <span>{data.subTitle}</span>
            </div>
        </div>
    )
}

/**
 * ChubbScreenBenefits Component displays benefits for Chubb insurance.
 * 
 * This component renders a list of benefits provided by Chubb insurance,
 * including an image and a brief description for each benefit.
 * 
 * @component
 * @example
 * return (
 *   <ChubbScreenBenefits />
 * )
 */
const ChubbScreenBenefits = () => {
    return (
        <div className={style.mainContainer}>
            {BENEFITS_DETAILS.map(data => <BenefitComponent data={data} />)}
        </div>
    )
}

export default ChubbScreenBenefits;