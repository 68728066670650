import '@ant-design/compatible/assets/index.css';
import {FormControlLabel, FormGroup} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';
import {LoadingButton} from '@mui/lab';
import {Box, Checkbox, Grid, Tab, Tabs, TextField} from '@mui/material';
import {Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import SkeletonCMF from 'src/components/Loading/skeleton';
import ModalGlobal from 'src/components/Modal/ModalGlobal';
import PDFView from 'src/components/PDFView';
import UniversalText from 'src/components/TitleInput/UniversalText';
import CountdownTimer from 'src/functions/CountdownTimer';
import useResponsive from 'src/hooks/useResponsive';
import {
  sendCallEid,
  sendSmsEid,
  verifyCallEid,
  verifySmsEid,
} from 'src/store/actions/eidServicesAction';
import {
  ActiveProductPrepaid,
  loadPDF,
  loadTermsPDF,
  loadToGetDocumentId,
} from 'src/store/actions/requestCard.actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {APP, ORIGIN} from 'src/utils/Constants';
import AirplanePaper from '../../assets/images/onboardingCredito/airplanePaper.svg';
import '../CardRequest/styles/form-style.scss';
import './modal.css';
import ModalProcessing from './Modals/ModalProcessing';
import SCREENS from './screens';
const styles = {
  formItem: {
    margin: 0,
    width: '100%',
  },
  label: {
    fontFamily: 'Red Hat Text', // Asegúrate de que la fuente esté cargada en tu proyecto
  },
};

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const secondsToExpire = 5;
const ButtonUniversal = styled(LoadingButton)(
  ({textColor, backgroundColor}) => ({
    letterSpacing: '0px',
    opacity: 1,
    borderRadius: '38px',
    color: textColor || '#FFFFFF',
    width: '274px',
    height: '76px',
    background: backgroundColor
      ? `transparent linear-gradient(270deg, ${backgroundColor} 0%, #399001 100%)`
      : 'transparent linear-gradient(270deg, #53A600 0%, #399001 100%)',
    [BreakPointTheme.breakpoints.down('xsm')]: {
      height: '50px',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      height: '55px',
    },
    '&:disabled': {
      background:
        'transparent linear-gradient(270deg, #D0D0D0 0%, #B0B0B0 100%)', // Cambia el color de fondo para el estado deshabilitado
      color: '#A0A0A0', // Cambia el color del texto para el estado deshabilitado
      cursor: 'not-allowed', // Cambia el cursor para indicar que el botón está deshabilitado
      opacity: 0.5, // Ajusta la opacidad si es necesario
    },
  }),
);
const ContentMain = styled('div')(() => ({
  display: ' flex',
  alignItems: ' center',
  flexDirection: ' column',
  width: ' 80%',
  margin: ' auto',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: ' 90%',
  },
}));
const TypographyTitle = styled('h1')(() => ({
  width: '100%',
  marginTop: '5vh !important',
  color: '#011E41',
  // fontSize: '2.5em',
  textAlign: 'initial',
  fontFamily: 'Red Hat Text',
  fontSize: 'clamp(25px, 2.5vw, 40px)',
  fontWeight: 'bold',
  // margin: '10vh auto 2vh 15vh',
  // [BreakPointTheme.breakpoints.down('sm')]: {
  //   font: 'normal normal 900 1.8em/35px Nunito',
  // },
}));
const AcceptConditionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '15px',
  paddingBottom: 25,
  [BreakPointTheme.breakpoints.down('lg1')]: {
    paddingTop: '5px',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    // paddingBottom: 0,
    flexDirection: 'column',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
const ColMain = styled(Grid)(() => ({
  alignItems: 'flex-start',
  [BreakPointTheme.breakpoints.down('sm')]: {
    textAlign: 'initial',
    font: 'normal normal normal 1em/31px Nunito',
  },
}));
const ColTerms = styled(Grid)(() => ({
  font: 'normal normal normal 1em/19px Nunito',
  paddingRight: 35,
  [BreakPointTheme.breakpoints.down('lg')]: {
    paddingRight: 0,
    paddingBottom: 25,
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    font: 'normal normal normal 1em/16px Nunito ',
  },
}));
const ModalDiv = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  height: '100%',
  marginTop: '1.5rem',
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
    paddingTop: '5%',
  },
}));

const ButtonModalWhite = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '9999px',
  borderWidth: '2px',
  width: '100%',
  backgroundColor: '#ffffff',
  borderColor: 'var(--light-green)',
  borderStyle: 'solid', // Agregamos el estilo de borde para que se vea
  color: '#026E18',
  marginTop: '16px',
  height: '60px',
  [theme.breakpoints.up('md')]: {
    marginTop: '17px',
    height: '65px',
  },
}));

const ButtonModalGreen = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '9999px',
  width: '100%',
  color: '#ffffff',
  height: '60px',
  [theme.breakpoints.up('md')]: {
    height: '65px',
  },
}));

const ImageSendIcon = styled('img')(({theme}) => ({
  width: '77px',
  height: '47px',
  marginBottom: '1rem',
  [theme.breakpoints.up('md')]: {
    height: '55px',
    width: '90px',
  },
}));

const ReadPdfPrepago = props => {
  const [code, setCode] = useState('');
  const [otherMethod, setActiveOther] = useState(false);
  const [callVerify, setCallVerify] = useState(false);
  const [smsVerify, setSmsVerify] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requestId, setId] = useState(null);
  const [signatureCreated, setValidSignature] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [loadingVerificationsign, setLoadingVerifySign] = useState(false);
  const [signDocument, setSignDocument] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [finallyTimer, setFinallyTimer] = useState(false);
  const {
    documentBase64,
    base64Terms,
    loadToPdf,
    documentId,
    identityId,
    onboardingId,
    onboardingSaved,
    form,
    loadToPdfTerms,
    costumerIdOnboarding
  } = useSelector(state => state.RequestCardReducer);
  const isDesktop = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = event => {
    // Obtén el valor del campo y convierte a mayúsculas
    const value = event.target.value.toLowerCase();

    // Limita el valor a 4 caracteres
    if (value.length <= 4 && /^[a-z0-9]*$/.test(value)) {
      setCode(value);
    }
  };

  useEffect(() => {
    dispatch(loadPDF(onboardingId, ORIGIN.PREPAGO, 'CTRP'));
    dispatch(loadToGetDocumentId(onboardingId, ORIGIN.PREPAGO, 'CTRP'));
  }, []);

  const sendSMS = () => {
    setLoadingVerification(true);
    const payload = {
      documentsId: [documentId],
      identityId: identityId,
      message: {
        phone: '+507' + form.cellphone,

        text:
          'Firme utilizando el siguiente codigo: *|challengeCode|* ,Valido por *|ttl|*.',
        from: APP.companyName,
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      stamp: {
        location: {
          page: 1,
          left: 210,
          top: 625,
          width: 200,
          height: 60,
        },
      },
      ttl: secondsToExpire,
      ttlUnit: 'm',
    };
    dispatch(sendSmsEid(payload))
      .then(response => {
        if (response.data.identity.status === 'VALID') {
          setId(response.data.requestId);
          setOpenModal(true);
          setSmsVerify(true);
          setFinallyTimer(false);
          setLoadingVerification(false);
        }
        // Maneja el éxito aquí, tal vez actualiza el estado o muestra un mensaje al usuario
      })
      .catch(error => {
        // Maneja el error aquí, tal vez muestra un mensaje de error al usuario
      })
      .finally(() => {
        setLoadingVerification(false);
      });
  };

  const verifySMS = () => {
    setLoadingVerifySign(true);
    const payload = {
      requestId,
      challengeCode: code,
      stamp: {
        // optional
        location: {
          page: 1,
          left: 210,
          top: 625,
          width: 200,
          height: 60,
        },
      },
    };
    dispatch(verifySmsEid(payload))
      .then(response => {
        if (response.data.id) {
          setSignDocument(response.data.documents[0].signedDocument.documentId);
          setOpenModal(false);
          setValidSignature(true);
        }

        // Maneja el éxito aquí, tal vez actualiza el estado o muestra un mensaje al usuario
      })
      .catch(error => {
        // Maneja el error aquí, tal vez muestra un mensaje de error al usuario
      })
      .finally(() => {
        setLoadingVerifySign(false);
      });
  };

  const sendCall = () => {
    setLoadingVerification(true);
    const payload = {
      documentsId: [documentId],
      identityId: identityId,
      message: {
        phone: '+507' + form.cellphone,
        text:
          'Hola, tu código para firmar el contrato CMF es: *|challengeCode|*. Repito*|challengeCode|*. Repito*|challengeCode|*.',
        from: '+50765978134',
        language: 'es',
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      stamp: {
        location: {
          page: 1,
          left: 210,
          top: 625,
          width: 200,
          height: 60,
        },
      },
      ttl: secondsToExpire,
      ttlUnit: 'm',
    };
    dispatch(sendCallEid(payload))
      .then(response => {
        if (response.data.identity.status === 'VALID') {
          setId(response.data.requestId);
          setOpenModal(true);
          setCallVerify(true);
          setLoadingVerification(false);
          setSmsVerify(false);
          setActiveOther(false);
          setFinallyTimer(false);
        }
      })
      .catch(error => {
        // Maneja el error aquí, tal vez muestra un mensaje de error al usuario
      })
      .finally(() => {
        setLoadingVerification(false);
      });
  };

  const verifyCall = () => {
    setLoadingVerifySign(true);
    const payload = {
      requestId,
      challengeCode: code,
      stamp: {
        location: {
          page: 1,
          left: 210,
          top: 625,
          width: 200,
          height: 60,
        },
      },
    };
    dispatch(verifyCallEid(payload))
      .then(response => {
        if (response.data.id) {
          setOpenModal(false);
          setValidSignature(true);
          setLoadingVerifySign(false);
          setSignDocument(response.data.documents[0].signedDocument.documentId);
        }

        // Maneja el éxito aquí, tal vez actualiza el estado o muestra un mensaje al usuario
      })
      .catch(error => {
        // Maneja el error aquí, tal vez muestra un mensaje de error al usuario
      })
      .finally(() => {
        setLoadingVerifySign(false);
      });
  };

  const onChange = (event, activeKey) => {
    setActiveTab(activeKey);
    switch (activeKey) {
      case '1':
        !documentBase64 &&
          dispatch(loadPDF(onboardingId, ORIGIN.PREPAGO, 'CTRP'));
        break;
      case '2':
        !base64Terms &&
          dispatch(loadTermsPDF(onboardingId, ORIGIN.PREPAGO, 'TYCP'));
        break;
      default:
    }
  };

  const onCompleteProduct = success => {
    setLoadingProduct(false);
    if (success) {
      navigate('/CongratsSign', {replace: true});
    }
  };

  const sendActiveProduct = () => {
    const payload = {
      ...onboardingSaved,
      customerId: costumerIdOnboarding,
      electronicDocumentId: documentId,
      electronicSignId: signDocument,
    };
    setLoadingProduct(true);
    dispatch(ActiveProductPrepaid(onboardingId, payload, onCompleteProduct));
  };

  const handleTimerFinish = () => {
    setFinallyTimer(2);
  };

  let pdfLoad;
  if (!loadToPdf && !loadToPdfTerms) {
    pdfLoad = false;
  } else {
    pdfLoad = true;
  }

  return (
    <Container>
      <ModalGlobal
        isOpen={openModal}
        onHide={() => setOpenModal(false)}
        bodyClassName={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          '@media (min-width: 768px)': {marginTop: '15px'},
        }}>
        <ModalDiv>
          {!callVerify && smsVerify && !otherMethod && (
            <>
              <ImageSendIcon src={AirplanePaper} alt="Airplane" />
              <Box
                sx={{
                  width: '75%',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}>
                <UniversalText
                  textType="h1"
                  multiline={true}
                  minSize={24}
                  maxSize={35}
                  text="Validación de la firma electrónica de tu contrato"
                  color="#011E41"
                  fontFamily="Arial, sans-serif"
                  textAlign="center"
                  style={{fontWeight: '600'}}
                />
                <UniversalText
                  textType="p"
                  multiline={true}
                  minSize={20}
                  maxSize={24}
                  text="Te hemos enviado un código por mensaje de texto a tu número de
                  celular, por favor ingrésalo a continuación"
                  color="#000000"
                  fontFamily="Arial, sans-serif"
                  textAlign="center"
                />
              </Box>
              <Box
                component="form"
                sx={{
                  width: '100%',
                  marginTop: '15px',
                  '@media (min-width: 768px)': {marginTop: '15px'},
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="outlined-controlled"
                  sx={{
                    paddingLeft: '1.25rem',
                    paddingRight: '1.25rem',
                    borderWidth: '1px',
                    outlineStyle: 'none',
                    width: '100%',
                    height: '70px',
                    borderColor: '#D0D5DD',
                  }}
                  placeholder="Ingresa el código de verificación aquí"
                  value={code}
                  onChange={handleChange}
                  inputProps={{maxLength: 4}} // Restringe la longitud máxima a 4 caracteres
                />
                {finallyTimer === 2 ? (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      loading={loadingVerification}
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      onClick={() => sendSMS()}>
                      Solicita un nuevo código
                    </ButtonUniversal>
                  </div>
                ) : (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p
                      style={{
                        textAlign: 'center',
                        color: ['16px', '#026E18'],
                        marginTop: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        '@media (min-width: 768px)': {marginTop: '5px'},
                      }}>
                      El código de verificación expirará en:{' '}
                      <CountdownTimer
                        minutes={5}
                        onFinish={handleTimerFinish}
                      />
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                  }}>
                  <a
                    onClick={() => setActiveOther(true)}
                    style={{
                      textAlign: 'left',
                      textDecoration: 'underline',
                      font: 'normal normal normal 16px/22px Red Hat Text',
                      letterSpacing: '0px',
                      color: '#026E18',
                      opacity: '1',
                    }}>
                    <u> ¿Quieres probar otro método de verificación?</u>
                  </a>
                </div>
                {finallyTimer !== 2 && (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      disabled={code.length < 4}
                      loading={loadingVerificationsign}
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      onClick={() => verifySMS()}>
                      Aceptar
                    </ButtonUniversal>
                  </div>
                )}
              </Box>
            </>
          )}
          {otherMethod && (
            <>
              <ImageSendIcon src={AirplanePaper} alt="send" />
              <UniversalText
                textType="h1"
                multiline={isDesktop ? false : true}
                minSize={22}
                maxSize={30}
                text="No hemos recibido la confirmación de tu firma"
                color="#011E41"
                fontFamily="Arial, sans-serif"
                textAlign="center"
                style={{padding: '2rem 0', fontWeight: '600'}}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '95%',
                  [BreakPointTheme.breakpoints.down('xsm')]: {
                    width: '100%',
                    fontWeight: '600',
                  },
                }}>
                <ButtonModalGreen
                  loading={loadingVerification}
                  onClick={() => {
                    sendSMS();
                    setActiveOther(false);
                    setCallVerify(false);
                  }}
                  style={{background: 'var(--linear-bg)'}}>
                  <UniversalText
                    textType="h1"
                    multiline={false}
                    minSize={20}
                    maxSize={25}
                    text="Solicita reenviar mensaje"
                    color="#FFFFFF"
                    fontFamily="Arial, sans-serif"
                    textAlign="center"
                    style={{margin: '20px'}}
                  />
                </ButtonModalGreen>
                <ButtonModalWhite
                  loading={loadingVerification}
                  onClick={() => {
                    sendCall();
                  }}>
                  <UniversalText
                    textType="h1"
                    multiline={false}
                    minSize={20}
                    maxSize={25}
                    text="Solicita validación por llamada de voz"
                    color="#026E18"
                    fontFamily="Arial, sans-serif"
                    textAlign="center"
                    style={{margin: '20px'}}
                  />
                </ButtonModalWhite>
              </Box>
            </>
          )}
          {callVerify && !smsVerify && !otherMethod && (
            <>
              <ImageSendIcon src={AirplanePaper} alt="Airplane" />
              <UniversalText
                textType="h1"
                multiline={true}
                minSize={24}
                maxSize={35}
                text="Validación de la firma electrónica de tu contrato"
                color="#011E41"
                fontFamily="Arial, sans-serif"
                textAlign="center"
                style={{fontWeight: '600'}}
              />
              <UniversalText
                textType="p"
                multiline={true}
                minSize={20}
                maxSize={24}
                text="Recibiras una llamada con un código a tu celular para validar la
                firma electrónica de tu contrato."
                color="#000000"
                fontFamily="Arial, sans-serif"
                textAlign="center"
              />
              <Box
                component="form"
                sx={{
                  width: '100%',
                  marginTop: '15px',
                  '@media (min-width: 768px)': {marginTop: '15px'},
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="outlined-controlled"
                  sx={{
                    paddingLeft: '1.25rem',
                    paddingRight: '1.25rem',
                    borderWidth: '1px',
                    outlineStyle: 'none',
                    width: '100%',
                    height: '70px',
                    borderColor: '#D0D5DD',
                  }}
                  placeholder="Ingresa el código de verificación aquí"
                  value={code}
                  onChange={handleChange}
                  inputProps={{maxLength: 4}} // Restringe la longitud máxima a 4 caracteres
                />
                {finallyTimer === 2 ? (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      loading={loadingVerification}
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      onClick={() => sendCall()}>
                      Solicita un nuevo código
                    </ButtonUniversal>
                  </div>
                ) : (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p
                      style={{
                        textAlign: 'center',
                        color: ['16px', '#026E18'],
                        marginTop: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        '@media (min-width: 768px)': {marginTop: '5px'},
                      }}>
                      El código de verificación expirará en:{' '}
                      <CountdownTimer
                        minutes={5}
                        onFinish={handleTimerFinish}
                      />
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                  }}>
                  <a
                    onClick={() => setActiveOther(true)}
                    style={{
                      textAlign: 'left',
                      textDecoration: 'underline',
                      font: 'normal normal normal 16px/22px Red Hat Text',
                      letterSpacing: '0px',
                      color: '#026E18',
                      opacity: '1',
                    }}>
                    <u> ¿Quieres probar otro método de verificación?</u>
                  </a>
                </div>
                {finallyTimer !== 2 && (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      disabled={code.length < 4}
                      onClick={() => verifyCall()}>
                      Aceptar
                    </ButtonUniversal>
                  </div>
                )}
              </Box>
            </>
          )}
        </ModalDiv>
      </ModalGlobal>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <Wrapper
        style={{flex: 1, height: '100%', width: '100%', overflowX: 'auto'}}>
        <Wrapper style={{alignItems: 'center'}}>
          <ContentMain>
            <TypographyTitle textType="subtitleApc" style={{paddingBottom: 25}}>
              Revisa y firma tu contrato
            </TypographyTitle>
            {loadingProduct && (
              <ModalProcessing
                isOpen={loadingProduct}
                onShow={loadingProduct}
                headingText={'Estamos validando tu firma electrónica.'}
                bodyText={'Aguarda un momento.'}
              />
            )}

            <Box>
              {pdfLoad ? (
                <div className="terms-and-pdf">
                  <SkeletonCMF />
                  <ModalProcessing
                    isOpen={pdfLoad}
                    onShow={pdfLoad}
                    headingText={'Estamos procesando tu contrato'}
                    bodyText={'Aguarda un momento.'}
                  />
                </div>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '60%',
                  }}>
                  <Tabs
                    value={activeTab}
                    onChange={onChange}
                    aria-label="document tabs">
                    <Tab
                      label="Contrato"
                      value="1"
                      style={{
                        textTransform: 'none',
                      }}
                    />
                    <Tab
                      label="Términos y condiciones"
                      value="2"
                      style={{
                        textTransform: 'none',
                      }}
                    />
                  </Tabs>
                  {activeTab === '1' && (
                    <Box className="terms-and-pdf">
                      {documentBase64 && <PDFView document={documentBase64} />}
                    </Box>
                  )}
                  {activeTab === '2' && (
                    <Box className="terms-and-pdf">
                      {base64Terms && <PDFView document={base64Terms} />}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <AcceptConditionContainer>
              <ColTerms style={{flex: 1.2}}>
                Para poder continuar con su solicitud, es necesario firmar el
                contrato de la tarjeta.
              </ColTerms>
              <ColMain
                style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <FormGroup style={styles.formItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptTerms}
                        // disabled={!this.props.documentBase64}
                        onChange={e => setAcceptTerms(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" style={styles.label}>
                        Acepto los Términos y Condiciones
                      </Typography>
                    }
                  />
                </FormGroup>
              </ColMain>
            </AcceptConditionContainer>
          </ContentMain>
        </Wrapper>
        <FooterOnboar
          back={
            <ButtonNextOnboarding
              variant="onboardingOutline"
              data-testid="firmar"
              onClick={() => {
                props.goToStep(SCREENS.CreditApproved);
              }}>
              Volver
            </ButtonNextOnboarding>
          }
          next={
            <>
              {!signatureCreated && (
                <ButtonNextOnboarding
                  variant="onboarding"
                  loading={loadingVerification}
                  disabled={!acceptTerms}
                  data-testid="firmar"
                  onClick={() => sendSMS()}>
                  Firmar
                </ButtonNextOnboarding>
              )}
              {signatureCreated && (
                <ButtonNextOnboarding
                  variant="onboarding"
                  loading={loadingProduct}
                  data-testid="firmar"
                  onClick={() => sendActiveProduct()}>
                  Enviar
                </ButtonNextOnboarding>
              )}
            </>
          }
        />
      </Wrapper>
    </Container>
  );
};

export default ReadPdfPrepago;
