import {FormControl, FormHelperText} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import ArrowCommon from '../../components/ArrowCommon';
import {
  COUNTRIES_TYPES,
  GENDER_TYPE,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM,
  SYSTEM_TYPES,
} from '../../utils/Constants.js';

import useForm from 'src/hooks/useForms';
import {UpdateOnboardingCredit} from 'src/store/actions/KO.Actions';
import {
  UpdateOnboardingCrePreap,
  createOrUpdate,
  editFormRequest,
  getCountries,
  getDistricts,
  getMaritalStatus,
  getOccupations,
  getProfessions,
  getProvinces,
  getTownships,
  UpdateOnboardingCrePPAGO,
} from 'src/store/actions/requestCard.actions';
import {
  FormControlData,
  MyBox,
  MyInputLabel,
  MyMenuItem,
  NumericResidencialInput,
  SelectComponent,
  TextFieldComponent,
} from './Component/FieldsForms';
import LayoutForm from './Layout/LayoutForm';

const initialData = {
  country: {
    name: 'country',
    label: 'País de residencia',
    errorMessage: 'Pais de residencia es requerido',
    delateDoubleSpace: true,
  },
  province: {
    name: 'province',
    label: 'Provincia',
    errorMessage: 'Provincia es requerido',
    delateDoubleSpace: true,
    onChancheClear: ['district', 'township'],
  },
  district: {
    name: 'district',
    label: 'Distrito',
    errorMessage: 'Distrito es requerido',
    delateDoubleSpace: true,
    onChancheClear: ['township'],
  },
  township: {
    name: 'township',
    label: 'Corregimiento',
    errorMessage: 'Corregimiento es requerido',
    delateDoubleSpace: true,
  },
  addressBuilding: {
    name: 'addressBuilding',
    label: 'Dirección / Calle de residencia',
    errorMessage: 'Dirección / Calle de residencia es requerido',
    delateDoubleSpace: true,
  },
  BuildingHouse: {
    name: 'BuildingHouse',
    label: 'Residencial / Barriada / Edificio',
    errorMessage: 'Residencial / Barriada / Edificio es requerido',
    delateDoubleSpace: true,
  },
  ApartmentHouseNumber: {
    name: 'ApartmentHouseNumber',
    label: 'Piso, apartamento o número de casa',
    errorMessage: 'Piso, apartamento o número de casa es requerido',
    delateDoubleSpace: true,
  },
  phone: {
    name: 'phone',
    label: 'Teléfono residencial',
    errorMessage: 'Teléfono residencial es requerido',
    isOptional: false,
    delateDoubleSpace: true,
  },
  civilStatus: {
    name: 'civilState',
    label: 'Estado civil',
    errorMessage: 'Estado civil es requerido',
    delateDoubleSpace: true,
  },
};

const regex = /^(?!.*\.\.)(?!^\s*$)[A-Za-z0-9\sñÑáéíóúÁÉÍÓÚ,.]+$/;

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .required(initialData.country.errorMessage)
    .default(''),
  province: Yup.string()
    .required(initialData.province.errorMessage)
    .default(''),
  district: Yup.string()
    .required(initialData.district.errorMessage)
    .default(''),
  township: Yup.string()
    .required(initialData.township.errorMessage)
    .default(''),
  addressBuilding: Yup.string()
    .required(initialData.addressBuilding.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  BuildingHouse: Yup.string()
    .required(initialData.BuildingHouse.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  ApartmentHouseNumber: Yup.string()
    .required(initialData.ApartmentHouseNumber.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  phone: Yup.string()
    .matches(
      /^(?!.*(.)\1{6})(([0-57-9]\d{6}))$/,
      'Formato de teléfono incorrecto.',
    )
    .required('El teléfono es obligatorio'),
  civilStatus: Yup.string()
    .required(initialData.civilStatus.errorMessage)
    .default(''),
});

const PersonalForm = ({verificationStep, setVerificationStep, props}) => {
  const dispatch = useDispatch();
  const valuePanama = COUNTRIES_TYPES.PANAMA;
  const labelPanama = COUNTRIES_TYPES.label;
  const [loading, setLoading] = useState(false);
  const formRequest = useSelector(state => state.RequestCardReducer.form);
  const {settings, provinceForm, occupationForm} = useSelector(
    state => state.RequestCardReducer,
  );
  const {
    formData,
    getValue,
    handleChange,
    handleBlur,
    handleSubmit,
    setOnChangeEvents,
    showError,
    getError,
    setInitialValues,
  } = useForm(initialData, validationSchema);
  const {
    countries,
    provinces,
    districts,
    maritalStatus,
    townships,
    origin,
  } = useSelector(state => state.RequestCardReducer);
  const {phoneForm, emailForm} = useSelector(state => state.RequestCardReducer);
  const setOnChangeEventsForm = useCallback(() => {
    const events = [
      {
        name: 'country',
        onChange: onChangeCounty(valuePanama),
      },
      {
        name: 'province',
        onChange: value => onChangeProvince(provinceForm),
      },
      {
        name: 'district',
        onChange: value => onChangeDistrict(value),
      },
      {
        name: 'township',
        onChange: value => dispatch(editFormRequest({township: value})),
      },
      {
        name: 'addressBuilding',
        onChange: value => dispatch(editFormRequest({addressBuilding: value})),
      },
      {
        name: 'BuildingHouse',
        onChange: value => dispatch(editFormRequest({BuildingHouse: value})),
      },
      {
        name: 'ApartmentHouseNumber',
        onChange: value =>
          dispatch(editFormRequest({ApartmentHouseNumber: value})),
      },
      {
        name: 'phone',
        onChange: value => dispatch(editFormRequest({phone: value})),
      },
      {
        name: 'civilStatus',
        onChange: value =>
          dispatch(editFormRequest({civilState: occupationForm})),
      },
    ];
    setOnChangeEvents(events);
  });

  const handleContinueClick = async () => {
    await handleSubmit(handleSubmitForm);
  };

  const onChangeCounty = valuePanama => {
    if (valuePanama) {
      dispatch(editFormRequest({country: valuePanama}));
    }
  };

  const onChangeProvince = useCallback(
    async province => {
      if (province) {
        dispatch(editFormRequest({province: province}));
      }
    },
    [getDistricts],
  );

  const onChangeDistrict = useCallback(
    async district => {
      if (district) {
        dispatch(editFormRequest({district: district}));
        await dispatch(getTownships(district));
      }
    },
    [getTownships],
  );

  const updateInitialValues = useCallback(() => {
    const initialValues = [
      {
        name: 'country',
        value: valuePanama,
      },
      {
        name: 'province',
        value: formRequest?.province,
      },
      {
        name: 'district',
        value: formRequest?.district,
      },
      {
        name: 'township',
        value: formRequest?.township,
      },
      {
        name: 'addressBuilding',
        value: formRequest?.addressBuilding,
      },
      {
        name: 'BuildingHouse',
        value: formRequest?.BuildingHouse,
      },
      {
        name: 'ApartmentHouseNumber',
        value: formRequest?.ApartmentHouseNumber,
      },
      {
        name: 'phone',
        value: formRequest?.phone,
      },

      {
        name: 'civilStatus',
        value: formRequest?.civilState,
      },
    ];
    setInitialValues(initialValues);
  }, []);

  const handleSubmitForm = () => {
    setLoading(true);
    const createdBy = SELLER_ID_TYPE;
    const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58';
    const typeIdentity = settings.identityTypes.find(
      item => item.value === 'Cédula',
    ).code;
    try {
      let phones = [];

      if (phoneForm) {
        phones.push({
          id: 1,
          typePhone: 'CE',
          area: '507',
          phone: phoneForm,
          isDefault: true,
          createdBy: createdBy,
          extension: null,
          lastModifiedBy: null,
        });
      }

      if (formRequest.phone) {
        phones.push({
          typePhone: 'CA',
          area: '507',
          phone: formRequest.phone,
          isDefault: false,
          createdBy: createdBy,
        });
      }
      const payload1 = {
        typeIdentityId: typeIdentity,
        branchOfficeId: branchOfficeId,
        sellerId: SELLER_ID_TYPE,
        approvedBy: SELLER_ID_TYPE,
        approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        maritalStatusId: formRequest.civilState,
        genderId:
          formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
        countryId: valuePanama,
        countryBirthId: formRequest.countryOfBirth,
        nationalityId: formRequest.nationality,
        systemId: SYSTEM_TYPES.CMF,
        identityNumber: formRequest.identity,
        firstName: formRequest.firstName,
        secondName: formRequest.secondName || null,
        firstSurname: formRequest.lastName,
        secondSurname: formRequest.secondLastName,
        dateOfBirth: formRequest.birthdate,
        idExpirationDate: formRequest.IDExpirationDate,
        phones: phones,
        emails: [
          {
            typeEmail: 'PE',
            email: emailForm,
            isDefault: true,
            createdBy: createdBy,
          },
        ],
        address: [
          {
            countryId: valuePanama,
            provinceId: formRequest.province,
            districtId: formRequest.district,
            townshipId: formRequest.township,
            address: formRequest.addressBuilding,
            createdBy: createdBy,
            buildingHouse: formRequest.BuildingHouse,
            ApartmentHouseNumber: formRequest.ApartmentHouseNumber,
          },
        ],
        images: [
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Frontal',
            link: formRequest.frontIdentityImage,
            thumbnails: null,
            createdBy: createdBy,
          },
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Reverso',
            link: formRequest.backIdentityImage,
            thumbnails: null,
            createdBy: createdBy,
          },
          {
            typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
            description: 'Biometrico',
            link: formRequest.faceImage,
            thumbnails: null,
            createdBy: createdBy,
          },
        ],
        createdBy: createdBy,
      };
      const payload = {
        typeIdentityId: typeIdentity,
        branchOfficeId: branchOfficeId,
        sellerId: SELLER_ID_TYPE,
        approvedBy: SELLER_ID_TYPE,
        approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        maritalStatusId: formRequest.civilState,
        genderId:
          formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
        countryId: valuePanama,
        countryBirthId: formRequest.countryOfBirth,
        nationalityId: formRequest.nationality,
        systemId: SYSTEM.VOLCAN,
        identityNumber: formRequest.identity,
        firstName: formRequest.firstName,
        secondName: formRequest.secondName || null,
        firstSurname: formRequest.lastName,
        secondSurname: formRequest.secondLastName,
        dateOfBirth: formRequest.birthdate,
        idExpirationDate: formRequest.IDExpirationDate,
        phones: phones,
        emails: [
          {
            typeEmail: 'PE',
            email: emailForm,
            isDefault: true,
            createdBy: createdBy,
          },
        ],
        address: [
          {
            countryId: valuePanama,
            provinceId: formRequest.province,
            districtId: formRequest.district,
            townshipId: formRequest.township,
            address: formRequest.addressBuilding,
            createdBy: createdBy,
            buildingHouse: formRequest.BuildingHouse,
            ApartmentHouseNumber: formRequest.ApartmentHouseNumber,
          },
        ],
        images: [
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Frontal',
            link: formRequest.frontIdentityImage,
            thumbnails: null,
            createdBy: createdBy,
          },
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Reverso',
            link: formRequest.backIdentityImage,
            thumbnails: null,
            createdBy: createdBy,
          },
          {
            typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
            description: 'Biometrico',
            link: formRequest.faceImage,
            thumbnails: null,
            createdBy: createdBy,
          },
        ],
        createdBy: createdBy,
      };
      if (origin === ORIGIN.PREAPROBADO) {
        dispatch(
          UpdateOnboardingCrePreap(payload1, onSuccess, 'UPDATE_PERSONAL_INFO'),
        );
      } else if (origin === ORIGIN.CREDITO) {
        dispatch(
          UpdateOnboardingCredit(payload, onSuccess, 'UPDATE_PERSONAL_INFO'),
        );
      } else if (origin === ORIGIN.PRECALIFICADO) {
        dispatch(createOrUpdate(payload, onSuccess, 'UPDATE_PERSONAL_INFO'));
      } else if (origin === ORIGIN.PREPAGO) {
        dispatch(
          UpdateOnboardingCrePPAGO(payload, onSuccess, 'UPDATE_PERSONAL_INFO'),
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const navigateToNextStep = () => {
    setVerificationStep(verificationStep + 1);
  };
  const onSuccess = success => {
    setLoading(false);
    if (success) {
      navigateToNextStep();
    }
  };
  useEffect(() => {
    updateInitialValues();
    setOnChangeEventsForm();
    dispatch(getCountries());
    dispatch(getOccupations());
    dispatch(getProfessions());
    dispatch(getMaritalStatus());
    dispatch(getDistricts(provinceForm));
  }, [getCountries]);

  return (
    <LayoutForm
      hasNextButton={true}
      hasBackButton={false}
      handleSubmit={handleContinueClick}
      loading={loading}
      props={props}>
      <MyBox
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'column',
          minWidth: '100%',
        }}>
        <MyBox>
          <MyBox>
            <FormControlData fullWidth error={showError('country')}>
              <MyInputLabel>{formData.country.label}</MyInputLabel>
              <SelectComponent
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
                id="select-country"
                label={formData.country.label}
                IconComponent={ArrowCommon}
                value={getValue('country')}
                disabled={true}
                onBlur={() => handleBlur('country')}
                className="">
                {countries?.map((option, index) => (
                  <MyMenuItem
                    key={index}
                    value={option.value}
                    defaultValue={labelPanama}>
                    {option.label}
                  </MyMenuItem>
                ))}
              </SelectComponent>
              {showError('country') && (
                <FormHelperText>{getError('country')}</FormHelperText>
              )}
            </FormControlData>
            <FormControlData fullWidth error={showError('province')}>
              <MyInputLabel>{formData.province.label}</MyInputLabel>
              <SelectComponent
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
                id="select-province"
                IconComponent={ArrowCommon}
                label={formData.province.label}
                value={getValue('province')}
                disabled={true}
                onBlur={() => handleBlur('province')}
                className="">
                {provinces?.map((option, index) => (
                  <MyMenuItem
                    key={index}
                    value={option.value}
                    defaultValue={provinceForm}>
                    {option.label}
                  </MyMenuItem>
                ))}
              </SelectComponent>
              {showError('province') && (
                <FormHelperText>{getError('province')}</FormHelperText>
              )}
            </FormControlData>
          </MyBox>
          <MyBox>
            <FormControl fullWidth error={showError('district')}>
              <MyInputLabel>{formData.district.label}</MyInputLabel>
              <SelectComponent
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
                id="select-district"
                IconComponent={ArrowCommon}
                label={formData.district.label}
                value={getValue('district')}
                onChange={e => handleChange('district', e.target.value)}
                onBlur={() => handleBlur('district')}
                className="">
                {districts?.map((option, index) => (
                  <MyMenuItem key={index} value={option.value}>
                    {option.label}
                  </MyMenuItem>
                ))}
              </SelectComponent>
              {showError('district') && (
                <FormHelperText>{getError('district')}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={showError('township')}>
              <MyInputLabel> {formData.township.label} </MyInputLabel>
              <SelectComponent
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
                id="select-township"
                IconComponent={ArrowCommon}
                label={formData.township.label}
                value={getValue('township')}
                onChange={e => handleChange('township', e.target.value)}
                onBlur={() => handleBlur('township')}
                className="">
                {townships?.map((option, index) => (
                  <MyMenuItem key={index} value={option.value}>
                    {option.label}
                  </MyMenuItem>
                ))}
              </SelectComponent>
              {showError('township') && (
                <FormHelperText>{getError('township')}</FormHelperText>
              )}
            </FormControl>
          </MyBox>
        </MyBox>
        <MyBox>
          <MyBox>
            <FormControl fullWidth error={showError('addressBuilding')}>
              <TextFieldComponent
                type="text"
                className=""
                InputProps={{
                  inputProps: {
                    'data-testid': 'addressBuilding', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                error={showError('addressBuilding')}
                placeholder={formData.addressBuilding.label}
                value={getValue('addressBuilding')}
                onChange={e => handleChange('addressBuilding', e.target.value)}
                onBlur={() => handleBlur('addressBuilding')}
              />
              {showError('addressBuilding') && (
                <FormHelperText>{getError('addressBuilding')}</FormHelperText>
              )}
            </FormControl>
          </MyBox>
          <MyBox>
            <FormControl fullWidth error={showError('BuildingHouse')}>
              <TextFieldComponent
                type="text"
                className=""
                InputProps={{
                  inputProps: {
                    'data-testid': 'BuildingHouse', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                error={showError('BuildingHouse')}
                placeholder={formData.BuildingHouse.label}
                value={getValue('BuildingHouse')}
                onChange={e => handleChange('BuildingHouse', e.target.value)}
                onBlur={() => handleBlur('BuildingHouse')}
              />
              {showError('BuildingHouse') && (
                <FormHelperText>{getError('BuildingHouse')}</FormHelperText>
              )}
            </FormControl>
          </MyBox>
        </MyBox>
        <MyBox>
          <MyBox>
            <FormControl fullWidth error={showError('ApartmentHouseNumber')}>
              <TextFieldComponent
                className=""
                InputProps={{
                  inputProps: {
                    'data-testid': 'ApartmentHouseNumber', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                error={showError('ApartmentHouseNumber')}
                placeholder={formData.ApartmentHouseNumber.label}
                value={getValue('ApartmentHouseNumber')}
                onChange={e =>
                  handleChange('ApartmentHouseNumber', e.target.value)
                }
                onBlur={() => handleBlur('ApartmentHouseNumber')}
              />
              {showError('ApartmentHouseNumber') && (
                <FormHelperText>
                  {getError('ApartmentHouseNumber')}
                </FormHelperText>
              )}
            </FormControl>
          </MyBox>
          <MyBox>
            <FormControl fullWidth error={showError('phone')}>
              <TextFieldComponent
                className=""
                error={showError('phone')}
                placeholder={formData.phone.label}
                value={getValue('phone')}
                InputProps={{
                  inputComponent: NumericResidencialInput,
                  inputProps: {
                    'data-testid': 'phone-input', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                onChange={e => handleChange('phone', e.target.value)}
                onBlur={() => handleBlur('phone')}
              />
              {showError('phone') && (
                <FormHelperText>{getError('phone')}</FormHelperText>
              )}
            </FormControl>
          </MyBox>
        </MyBox>
        <MyBox>
          <FormControlData
            sx={{
              width: '49%',
              '@media (max-width: 1024px)': {
                width: '100%', // Altera a largura para 100% quando a largura da tela for menor ou igual a 600px
              },
            }}
            error={showError('civilStatus')}>
            <MyInputLabel>{formData.civilStatus.label}</MyInputLabel>
            <SelectComponent
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              data-testid="civilStatus"
              IconComponent={ArrowCommon}
              label={formData.civilStatus.label}
              disabled={true}
              value={getValue('civilStatus')}
              // onChange={e => handleChange('civilStatus', e.target.value)}
              onBlur={() => handleBlur('civilStatus')}>
              {maritalStatus?.map((option, index) => (
                <MyMenuItem
                  key={index}
                  value={option.value}
                  defaultValue={occupationForm}>
                  {option.label}
                </MyMenuItem>
              ))}
            </SelectComponent>
            {showError('civilStatus') && (
              <FormHelperText>{getError('civilStatus')}</FormHelperText>
            )}
          </FormControlData>
        </MyBox>
      </MyBox>
    </LayoutForm>
  );
};

export default PersonalForm;
