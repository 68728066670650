import {Form} from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {FormControlLabel, FormGroup} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material'
import {Modal, Spin, Typography, message} from 'antd'
import Axios from 'axios'
import moment from 'moment'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Navigate} from 'react-router-dom'
import {default as SignatureCanvas} from 'react-signature-canvas'
import WrapperSide from 'src/components/Aside/Wrapper'
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding'
import FooterOnboar from 'src/components/Footer/FooterOnboarding'
import SkeletonCMF from 'src/components/Loading/skeleton'
import ModalCommon from 'src/components/Modal/ModalCommon'
import {BreakPointTheme} from 'src/theme/breakpoints'
import phoneHeaderLogo from '../../assets/images/onboarding/paper-plane.svg'
import CallVerify from '../../assets/images/onboardingCredito/CallVerify.svg'
import AirplanePaper from '../../assets/images/onboardingCredito/airplanePaper.svg'
import check from '../../assets/images/onboardingCredito/checkMark.svg'
import PDFView from '../../components/PDFView'
import {SET_DATA_CLEAN} from '../../constrants/reducers.js'
import {checkStep} from '../../store/actions/Steps.Action.js'
import {
  activateProduct,
  addDocument,
  clearPDF,
  getBannerActive,
  headerEID,
  loadPDF,
  loadTermsPDF,
  logsOnboarding,
  sendSMS,
  sendSignByVoice,
  updateImgOnboardingCurrent,
} from '../../store/actions/requestCard.actions'
import {APP, DIALOG_TYPES, STATUS_SIGNATURES} from '../../utils/Constants'
import {ConsoleHelper} from '../../utils/Functions'
import '../CardRequest/styles/form-style.scss'
import '../CardRequest/styles/styleSign.scss'
import {Button} from './Component/Button'
import ModalProcessing from './Modals/ModalProcessing'
import SCREENS from './screens'
const styles = {
  formItem: {
    margin: 0,
    width: '100%',
  },
  label: {
    fontFamily: 'Red Hat Text', // Asegúrate de que la fuente esté cargada en tu proyecto
  },
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const secondsToExpire = 120
const ButtonUniversal = styled(LoadingButton)(() => ({
  font:
    'normal normal normal clamp(18px, 2.5vw, 20px) / Red Hat Text !important',
  letterSpacing: '0px',
  fontWeight: '600 !important',
  opacity: 1,
}))
const ContentMain = styled('div')(() => ({
  display: ' flex',
  alignItems: ' center',
  flexDirection: ' column',
  width: ' 80%',
  margin: ' auto',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: ' 90%',
  },
}))
const TypographyTitle = styled('h1')(() => ({
  width: '100%',
  marginTop: '5vh !important',
  color: '#011E41',
  // fontSize: '2.5em',
  textAlign: 'initial',
  fontFamily: 'Red Hat Text',
  fontSize: 'clamp(25px, 2.5vw, 40px)',
  fontWeight: 'bold',
  // margin: '10vh auto 2vh 15vh',
  // [BreakPointTheme.breakpoints.down('sm')]: {
  //   font: 'normal normal 900 1.8em/35px Nunito',
  // },
}))
const AcceptConditionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '4%',
  paddingBottom: 25,
  [BreakPointTheme.breakpoints.down('lg1')]: {
    paddingTop: 0,
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    // paddingBottom: 0,
    flexDirection: 'column',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
const ColMain = styled(Grid)(() => ({
  alignItems: 'flex-start',
  [BreakPointTheme.breakpoints.down('sm')]: {
    textAlign: 'initial',
    font: 'normal normal normal 1em/31px Nunito',
  },
}))
const ColTerms = styled(Grid)(() => ({
  font: 'normal normal normal 1em/19px Nunito',
  paddingRight: 35,
  [BreakPointTheme.breakpoints.down('lg')]: {
    paddingRight: 0,
    paddingBottom: 25,
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    font: 'normal normal normal 0.75em/16px Nunito ',
  },
}))
const ModalDiv = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  height: '100%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
    paddingTop: '5%',
  },
}))
class SignPDFDefault extends Component {
  constructor(props) {
    super(props)
    this.sigPad = React.createRef()
    this.state = {
      code: null,
      documentLoaded: false,
      documentRendered: false,
      countDown: moment().add(secondsToExpire, 'seconds'),
      documentBase64: this.props.documentBase64,
      name: this.props.name || 'User',
      loading: false,
      loadingSignButton: false,
      modalVisible: false,
      loadingModal: false,
      smsValidated: false,
      signDocument: null,
      loadingProd: this.props.LoadingactivePro,
      activeSMS: false,
      terms: false,
      signatureContract1: null,
      trimmedDataURL: null,
      sign: false,
      visibleIndication: false,
      modalSign: false,
      termsLetter: false,
      visibleSign: true,
      isEmpty: true,
      activeKey: '1',
      _UploadDocs: false,
      sigWrapperWidth: null,
      activeTab: '1',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.dialog?.id !== this.props?.dialog?.id) {
      const {message: dialogType} = this.props.dialog

      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

      if (dialogType === DIALOG_TYPES.SUCCESS) {
      }
    }
  }
  componentDidMount() {
    /* obtener documento PDF del Onboarding */
    const {onboardingId, origin} = this.props

    //
    this.props.clearPDF()
    this.props.clearLinkSign()
    this.props.loadPDF(onboardingId, origin, 'CTR')

    // TODO: remove for test purposes only
  }
  start() {
    const _time = moment().add(secondsToExpire, 'seconds')
    this.setState({countDown: _time}, () => this.tick())
  }

  tick() {
    const {countDown} = this.state
    const diff = countDown.diff(moment())
    const timerDown = moment.utc(diff)
    this.setState({timerDown: timerDown, diff})
    if (diff > 0) {
      setTimeout(this.tick.bind(this), 1000)
    }
  }

  stop() {
    this.setState({timerDown: 0, diff: 0})
  }
  /* send the validation signature message */
  sendSMS() {
    const {documentId, identityId, formRequest} = this.props
    const payload = {
      documentsId: [documentId],
      identityId: identityId,
      message: {
        phone: '+507' + formRequest.cellphone,

        text:
          'Firme utilizando el siguiente codigo: *|challengeCode|* ,Valido por *|ttl|*.',
        from: APP.companyName,
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      stamp: {
        // optional
        location: {
          page: 1,
          left: 240,
          top: 715,
          width: 200,
          height: 60,
        },
      },
      ttl: secondsToExpire,
      ttlUnit: 's',
    }
    try {
      this.props.sendSMS(payload)
    } catch (e) {
      ConsoleHelper('error', e)
    }

    this.start()
  }
  /**
   * @description: Los procesos de firma se completa mediante la publicación de una solicitud en este caso /v2/signatures/sign/voice
   * Una vez se valida que el codigo de verificacion es correcto de la llamada es correcto se procede a dejar continuar el proceso de firma
   */
  async validateCall() {
    const {requestId, origin, onboardingId} = this.props
    const payload = {
      requestId,
      challengeCode: this.state.code,
      stamp: {
        // optional
        location: {
          page: 1,
          left: 240,
          top: 715,
          width: 200,
          height: 60,
        },
      },
    }
    try {
      this.setState({loadingSignButton: true})

      const res = await Axios.post(
        `${APP.electronicId.endpoints.signByCall}`,
        payload,
        headerEID,
      )
      logsOnboarding(
        onboardingId,
        origin,
        'SIGID_CODE_CALL',
        res.data,
        payload,
        true,
      )

      if (res.data.id) {
        this.setState({
          smsValidated: true,
          signDocument: res.data.documents[0].signedDocument.documentId,
        })
        this.completeSignature()
        this.onCloseModal()
      }
    } catch (err) {
      logsOnboarding(
        onboardingId,
        origin,
        'SIGID_CODE_CALL',
        err?.response?.data,
        payload,
        false,
      )
      this.setState({loadingSignButton: false})
      message.error('El código ingresado es inválido')
    }
  }
  /**
   * @description: Funcion que se encarga de enviar Signature by Voice
   */
  sendSignByVoice() {
    const {documentId, identityId, formRequest} = this.props
    const payload = {
      documentsId: [documentId],
      identityId: identityId,
      message: {
        phone: '+507' + formRequest.cellphone,
        text:
          'Hola, tu código para firmar el contrato CMF es: *|challengeCode|*. Repito*|challengeCode|*. Repito*|challengeCode|*.',
        from: '+50765978134',
        language: 'es',
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      stamp: {
        location: {
          page: 1,
          left: 240,
          top: 715,
          width: 200,
          height: 60,
        },
      },
      ttl: 2,
      ttlUnit: 'm',
    }
    try {
      this.props.sendSignByVoice(payload)
    } catch (e) {
      ConsoleHelper('error', e)
    }

    this.start()
  }
  checkSMS() {
    const pendings = [
      STATUS_SIGNATURES.AwaitingConfirmation,
      STATUS_SIGNATURES.Requested,
    ]

    const {countDown} = this.state
    const diff = countDown.diff(moment())

    if (pendings.includes(this.props.statusSignature) && diff > 0) {
      this.props.verifySignature('sms', this.props.requestId)
      setTimeout(() => {
        this.checkSMS()
      }, 4000)
    }
  }
  /**
   * @description: Los procesos de firma se completa mediante la publicación de una solicitud en este caso /v2/signatures/sign/voice
   * Una vez se valida que el codigo de verificacion es correcto de la llamada es correcto se procede a dejar continuar el proceso de firma
   */
  renderPDF() {
    return <PDFView document={this.props.documentBase64} />
  }
  renderTerms = () => {
    return <PDFView document={this.props.base64Terms} />
  }
  completeSignature() {
    this.setState({loadingSignButton: false})
  }

  activateProduct = async () => {
    const {productId, requestVideoId, documentId} = this.props
    this.setState({loadingSignButton: true})
    await this.props.activateProduct(
      productId,
      requestVideoId,
      documentId,
      this.state.signDocument,
    )
    this.setState({loadingSignButton: false})
  }

  async validateSMS() {
    const {requestId, origin, onboardingId} = this.props
    const payload = {
      requestId,
      challengeCode: this.state.code,
      stamp: {
        // optional
        location: {
          page: 1,
          left: 240,
          top: 715,
          width: 200,
          height: 60,
        },
      },
    }
    try {
      this.setState({loadingSignButton: true})
      const res = await Axios.post(
        `${APP.electronicId.endpoints.signSms}`,
        payload,
        headerEID,
      )
      logsOnboarding(
        onboardingId,
        origin,
        'SIGID_CODE_SMS',
        res.data,
        payload,
        true,
      )
      if (res.data.id) {
        this.setState({
          smsValidated: true,
          signDocument: res.data.documents[0].signedDocument.documentId,
        })
        this.completeSignature()
        this.onCloseModal()
      }
    } catch (err) {
      const error = err?.response?.data?.message
      logsOnboarding(
        onboardingId,
        origin,
        'SIGID_CODE_SMS',
        err?.response?.data,
        payload,
        false,
      )
      this.setState({loadingSignButton: false})
      message.error(
        error
          ? `Sistema biométrico, ${error}`
          : 'El código ingresado es inválido',
      )
    }
  }

  renderButtonSign() {
    return (
      <div style={{position: 'fixed', zIndex: 1, bottom: '10px'}}>
        <Button
          loading={this.state.loadingModal}
          type="primary"
          onClick={this.onPressSignButton.bind(this)}>
          Firmar Documento
        </Button>
      </div>
    )
  }
  onPressCallButton() {
    /* validation */
    if (this.props.documentBase64) {
      this.signCallButton()
    }
  }
  signCallButton() {
    this.setState({loadingModal: true})
    setTimeout(() => {
      this.setState({
        modalVisible: true,
        loadingModal: false,
        activeSMS: false,
      })
      this.sendSignByVoice()
    }, 1000)
  }
  onPressSignButton() {
    /* validation */
    if (this.props.identityId && this.props.documentBase64) {
      this.signButton()
      this.props.getBannerActive()
    }
  }

  onCloseModal() {
    this.stop()
    this.setState({modalVisible: false})
  }

  signButton() {
    this.setState({
      loadingModal: false,
      modalSign: true,
      visibleSign: true,
    })
  }

  /**
   * Modal para firma de descuento
   * @returns
   */
  onCancelIntruccion() {
    this.setState({
      visibleIndication: false,
    })
  }
  onCancel() {
    this.sigPad.clear()
    this.setState({
      modalSign: false,
      isEmpty: true,
    })
  }
  clear = () => {
    this.sigPad.clear()
    this.setState({
      isEmpty: true,
    })
  }
  SignatureCanvasIsEmpty() {
    const signatureCanvas = this.sigPad
    this.setState({
      isEmpty: signatureCanvas.isEmpty(),
    })
  }
  onFinish(success) {
    if (success === false) {
      this.setState({
        _UploadDocs: false,
      })
    } else if (success === true) {
      this.setState({
        _UploadDocs: false,
        modalSign: false,
        visibleIndication: true,
        visibleSign: false,
      })
    }
  }
  renderSignPaint() {
    const {signCapture, identityId, onboardingId, origin} = this.props
    const handleClick = () => {
      this.setState({_UploadDocs: true})
      this.props.updateImgOnboardingCurrent(
        `${identityId}.sign.jpeg`,
        this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
        onboardingId,
        origin,
        'CTR',
        this.onFinish.bind(this),
      )
    }

    return (
      <Dialog
      style={{ zIndex: 100 }}
        centter
        open={this.state.modalSign}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        componentsProps={{
          backdrop: {
            style: {
              backgroundColor: 'transparent',
              boxShadow:
                '0px 11px 15px -7px rgb(1 7 12 / 20%), 0px 24px 38px 3px rgb(171 145 156 / 8%), 0px 9px 46px 8px rgb(25 24 47) !important',
            },
          },
        }}>
        <DialogTitle
          sx={{
            color: '#011E41',
            fontSize: 'clamp(18px, 2.5vw, 25px) !important',
            fontWeight: '600 !important',
          }}>
          Añadir firma digital
          <IconButton
            aria-label="close"
            onClick={this.onCancel.bind(this)}
            style={{position: 'absolute', right: '8px', top: '8px'}}>
            <CancelIcon
              sx={{
                width: '37px',
                height: '37px',
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            ref={ref => {
              if (
                ref &&
                ref.getBoundingClientRect().width - 2 !==
                  this.state.sigWrapperWidth
              ) {
                this.setState({
                  sigWrapperWidth: ref.getBoundingClientRect().width - 2,
                })
              }
            }}
            className="sigContainer">
            <SignatureCanvas
              penColor="black"
              ref={ref => {
                this.sigPad = ref
              }}
              canvasProps={{
                width: this.state.sigWrapperWidth,
                height: 200,
              }}
              onEnd={_e => {
                this.SignatureCanvasIsEmpty()
              }}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: '1rem',
            [BreakPointTheme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'column-reverse',
            },
          }}>
          <ButtonUniversal
            sx={{
              width: '240px',
              height: '53px',
              opacity: 1,
              borderRadius: '38px',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '95%',
                height: '60px',
                borderRadius: '30px',
              },
            }}
            variant="onboardingOutline"
            onClick={this.clear}>
            Limpiar
          </ButtonUniversal>
          <ButtonUniversal
            sx={{
              width: '240px',
              height: '53px',
              opacity: 1,
              borderRadius: '38px',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '95%',
                height: '60px',
                marginBottom: '1rem',
                borderRadius: '30px',
              },
            }}
            variant="onboarding"
            disabled={this.state.isEmpty}
            loading={signCapture}
            onClick={handleClick}>
            Aceptar
          </ButtonUniversal>
        </DialogActions>
      </Dialog>
    )
  }
  /**
   * Modal de instruccion
   */
  nextSignEID() {
    const handleClick = () => {
      this.setState({
        modalVisible: true,
        loadingModal: false,
        activeSMS: true,
        visibleIndication: false,
      })
      this.sendSMS()
    }

    return (
      <ModalCommon
        isOpen={this.state.visibleIndication}
        onHide={this.onCancelIntruccion.bind(this)}
        modalClassName={{
          overlay: 'bg-[rgba(0,0,0,0.8)]',
          modal:
            'border border-2 border-gray-300 relative w-full md:w-[70%] lg:w-[989px] h-full md:h-[50%] lg:h-[636px] m-0',
          closeButton: 'hidden',
        }}>
        <div className="w-full flex items-center justify-center flex-col ">
          <div className="w-full  mt-[93px] md:mt-[62px]  flex items-center justify-center flex-col ">
            <img
              src={check}
              className="w-[71.04px] h-[71.04px]"
              alt="checkmark"
            />
            <h1 className="text-[#011E41] font-semibold text-2xl text-[22px] md:text-[30px] text-center mt-[16px] md:px-10">
              Tú firma digital se ha agregado al siguiente documento:
            </h1>
            <ul className="mt-[38px] list-disc">
              <li className="text-lg md:text-[25px]">
                Carta de descuento directo
              </li>
            </ul>

            <div className="flex w-full mt-[63px] md:mt-[30px]">
              <ButtonUniversal
                style={{background: 'var(--linear-bg)', textTransform: 'none'}}
                className="hidden mx-[52px]  text-white md:text-[25px] rounded-full md:flex items-center justify-center h-[76px] w-full "
                onClick={handleClick}>
                Continua con firma electrónica de tu contrato
              </ButtonUniversal>
              <ButtonUniversal
                style={{background: 'var(--linear-bg)'}}
                className=" md:hidden mx-[25px]  text-white text-[20px] rounded-full flex items-center justify-center h-[60px] w-full "
                onClick={handleClick}>
                Continuar
              </ButtonUniversal>
            </div>
          </div>
        </div>
      </ModalCommon>
    )
  }

  renderModalSign() {
    return (
      <>
        <ModalCommon
          isOpen={true}
          onHide={this.onCloseModal.bind(this)}
          bodyClassName="mt-[10px] md:mt-[15px] flex flex-col items-center justify-center">
          <ModalDiv>
            {this.state.activeSMS ? (
              <>
                <img src={AirplanePaper} alt='Airplane' />
                <h1 className="text-[#011E41] font-semibold text-[20px] md:text-[25px] mt-[12px] text-center md:leading-[25px]">
                  Validación de la firma electrónica de tu contrato
                </h1>
                <p className="text-base md:text-xl mt-[9px] md:mt-[14px] text-center">
                  Te hemos enviado un código por mensaje de texto a tu número de
                  celular, por favor ingrésalo a continuación
                </p>

                <div className="mt-[15px] md:mt-[15px] w-full">
                  <input
                    className="outline-none  w-full h-[70px] rounded-[15px] border border-[#D0D5DD] px-5 placeholder:text-gray-400"
                    placeholder="Ingresa el código de verificación aquí"
                    onChange={e => {
                      this.setState({code: e.target.value.toLowerCase()})
                      e.target.value = e.target.value.toLowerCase()
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px 0px',
                    }}>
                    <a
                      onClick={this.onPressCallButton.bind(this)}
                      style={{
                        textAlign: 'left',
                        textDecoration: 'underline',
                        font: 'normal normal normal 16px/22px Red Hat Text',
                        letterSpacing: '0px',
                        color: '#026E18',
                        opacity: '1',
                      }}>
                      {' '}
                      <u> No has recibido tu sms, prueba con una llamada</u>
                    </a>
                  </div>

                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      style={{background: 'var(--linear-bg)'}}
                      className=" text-white rounded-full flex items-center
                      justify-center h-[60px] md:h-[76px] text-xl md:text-[25px]
                      w-full md:w-[70%] lg:w-[50%]"
                      loading={this.state.loadingSignButton}
                      onClick={this.validateSMS.bind(this)}>
                      {' '}
                      Aceptar
                    </ButtonUniversal>
                  </div>
                </div>
              </>
            ) : (
              <>
                <img src={CallVerify} alt="sendCall" />
                <h1 className="text-[#011E41] font-semibold text-[20px] md:text-[25px] mt-[20px] text-center md:leading-[25px]">
                  Validación de la firma electrónica de tu contrato
                </h1>
                <p className="text-base md:text-xl mt-[9px] md:mt-[14px] text-center">
                  Recibiras una llamada con un código a tu celular para validar
                  la firma electrónica de tu contrato.
                </p>

                <div className="mt-[15px] md:mt-[15px] w-full">
                  <input
                    className="outline-none  w-full h-[70px] rounded-[15px] border border-[#D0D5DD] px-5 placeholder:text-gray-400"
                    placeholder="Ingresa el código de verificación aquí"
                    onChange={e => {
                      this.setState({code: e.target.value.toLowerCase()})
                      e.target.value = e.target.value.toLowerCase()
                    }}
                  />

                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      style={{background: 'var(--linear-bg)'}}
                      className=" text-white rounded-full flex items-center
                      justify-center h-[60px] md:h-[76px] text-xl md:text-[25px]
                      w-full md:w-[70%] lg:w-[50%]"
                      loading={this.state.loadingSignButton}
                      onClick={this.validateCall.bind(this)}>
                      {' '}
                      Aceptar
                    </ButtonUniversal>
                  </div>
                </div>
              </>
            )}
          </ModalDiv>
        </ModalCommon>
      </>
    )
  }

  previousStep() {
    this.props.previousStep()
  }

  renderBackButton() {
    return this.props.canReturn ? (
      <ButtonNextOnboarding
        variant="borderContained"
        loading={false}
        disabled={false}
        onClick={this.previousStep.bind(this)}>
        Volver
      </ButtonNextOnboarding>
    ) : (
      <div></div>
    )
  }

  notification() {
    return (
      <Modal
        centered
        open={true}
        maskClosable={false}
        closeIcon={false}
        destroyOnClose={false}
        wrapClassName="container-validation"
        footer={false}
        onCancel={this.onCloseModal.bind(this)}
        width={400}>
        <div className="validation-container">
          <img src={phoneHeaderLogo} alt='Phone Header Logo' />
          <Spin size="large"></Spin>
        </div>
      </Modal>
    )
  }
  onChange = (event, activeKey) => {
    const {onboardingId, origin} = this.props
    this.setState({activeTab: activeKey})
    switch (activeKey) {
      case '1':
        !this.props.documentBase64 &&
          this.props.loadPDF(onboardingId, origin, 'CTR')
        break
      case '2':
        !this.props.base64Terms &&
          this.props.loadPDFTerms(onboardingId, origin, 'TYC')
        break
      default:
    }
  }
  render() {
    const {LoadingactivePro, loadToPdf, productIsActive} = this.props
    const {_UploadDocs} = this.state
    let termsBoolean = true
    if (
      this.state.terms === true &&
      this.state.termsLetter === true &&
      this.state.terms === this.state.termsLetter
    ) {
      termsBoolean = false
    } else {
      termsBoolean = true
    }
    const {activeTab} = this.state
    return (
      <Container>
        {productIsActive && <Navigate to="/CongratsSign" replace={true} />}

        <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
        <Wrapper
          style={{flex: 1, height: '100%', width: '100%', overflowX: 'auto'}}>
          <Wrapper style={{alignItems: 'center'}}>
            <ContentMain>
              {_UploadDocs && (
                <ModalProcessing
                  isOpen={_UploadDocs}
                  onShow={_UploadDocs}
                  headingText={'Estamos guardando tu firma.'}
                  bodyText={'Aguarda un momento.'}
                />
              )}
              {LoadingactivePro && (
                <ModalProcessing
                  isOpen={LoadingactivePro}
                  onShow={LoadingactivePro}
                  headingText={'Estamos validando tu firma electrónica.'}
                  bodyText={'Aguarda un momento.'}
                />
              )}
              <TypographyTitle
                textType="subtitleApc"
                style={{paddingBottom: 25}}>
                Revisa y firma tu contrato y carta de descuento directo
              </TypographyTitle>
              {loadToPdf ? (
                <div className="terms-and-pdf">
                  <SkeletonCMF />
                  <ModalProcessing
                    isOpen={loadToPdf}
                    onShow={loadToPdf}
                    headingText={'Estamos procesando tu contrato'}
                    bodyText={'Aguarda un momento.'}
                  />
                </div>
              ) : (
                <Box sx={{width: '100%', height: '60%'}}>
                  <Tabs
                    value={activeTab}
                    onChange={this.onChange}
                    aria-label="document tabs">
                    <Tab
                      label="Contrato y carta de descuento directo"
                      value="1"
                      style={{
                        textTransform: 'none',
                      }}
                    />
                    <Tab
                      label="Términos y condiciones"
                      value="2"
                      style={{
                        textTransform: 'none',
                      }}
                    />
                  </Tabs>
                  {activeTab === '1' && (
                    <Box className="terms-and-pdf">
                      {this.props.documentBase64 && this.renderPDF()}
                    </Box>
                  )}
                  {activeTab === '2' && (
                    <Box className="terms-and-pdf">
                      {this.props.base64Terms && this.renderTerms()}
                    </Box>
                  )}
                </Box>
              )}
              <AcceptConditionContainer>
                <ColTerms style={{flex: 1.2}}>
                  Para poder continuar con su solicitud, es necesario firmar el
                  contrato de la tarjeta y la carta de descuento, la cual podrá
                  ser utilizada para garantizar el cumplimiento de las
                  obligaciones del tarjetahabiente.
                </ColTerms>
                <ColMain
                  style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                  <FormGroup style={styles.formItem}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.terms}
                          disabled={!this.props.documentBase64}
                          onChange={e =>
                            this.setState({terms: e.target.checked})
                          }
                        />
                      }
                      label={
                        <Typography variant="subtitle1" style={styles.label}>
                          Acepto los Términos y Condiciones
                        </Typography>
                      }
                    />
                  </FormGroup>

                  <FormGroup style={styles.formItem}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.termsLetter}
                          onChange={e =>
                            this.setState({termsLetter: e.target.checked})
                          }
                          disabled={!this.props.documentBase64}
                        />
                      }
                      label={
                        <Typography variant="subtitle1" style={styles.label}>
                          Acepto firmar la Carta de Descuento
                        </Typography>
                      }
                    />
                  </FormGroup>
                </ColMain>
              </AcceptConditionContainer>
            </ContentMain>
          </Wrapper>
          <FooterOnboar
            back={
              <ButtonNextOnboarding
                variant="onboardingOutline"
                data-testid="firmar"
                onClick={() => {
                  this.props.goToStep(SCREENS.CreditApproved)
                }}>
                Volver
              </ButtonNextOnboarding>
            }
            next={
              <>
                {!this.state.modalVisible &&
                  this.props.documentBase64 &&
                  !this.state.smsValidated && (
                    <ButtonNextOnboarding
                      variant="onboarding"
                      loading={this.state.loadingModal}
                      disabled={termsBoolean}
                      data-testid="firmar"
                      onClick={this.onPressSignButton.bind(this)}>
                      Firmar
                    </ButtonNextOnboarding>
                  )}
                {this.state.smsValidated && this.state.signDocument && (
                  <ButtonNextOnboarding
                    variant="onboarding"
                    loading={this.state.loadingSignButton}
                    disabled={this.state.loadingSignButton}
                    id="392717287871632"
                    data-testid="firmar"
                    onClick={() => {
                      this.activateProduct(this)
                    }}>
                    Enviar
                  </ButtonNextOnboarding>
                )}
              </>
            }
          />
        </Wrapper>
        {this.state.modalVisible && this.renderModalSign()}
        {this.state.visibleSign && this.renderSignPaint()}
        {this.state.visibleIndication && this.nextSignEID()}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  let {
    form,
    identityId,
    documentId,
    onboardingId,
    signatureRequest,
    documentBase64,
    productId,
    requestVideoId,
    dialog,
    productIsActive,
    onboardingSaved,
    LoadingactivePro,
    origin,
    newCode,
    routeCreateUser,
    linkImgSign,
    base64Terms,
    loadToPdf,
    signCapture,
  } = state.RequestCardReducer
  let {IdOnboarding, productIsActiveCred} = state.KOReducer
  return {
    onboardingId,
    signCapture,
    documentBase64,
    requestVideoId,
    formRequest: form,
    requestId: signatureRequest,
    statusSignature: signatureRequest?.status,
    identityId,
    documentId,
    productId,
    dialog,
    productIsActive,
    LoadingactivePro,
    onboardingSaved,
    IdOnboarding,
    origin,
    productIsActiveCred,
    newCode,
    routeCreateUser,
    linkImgSign,
    base64Terms,
    loadToPdf,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadPDF: (onboarding, origin, templateName) =>
      dispatch(loadPDF(onboarding, origin, templateName)),
    uploadDocumentPDF: (name, onboardingId, origin, templateName) =>
      dispatch(addDocument(name, onboardingId, origin, templateName)),
    sendSMS: payload => dispatch(sendSMS(payload)),
    activateProduct: (productId, videoId, documentId, signId, onComplete) =>
      dispatch(
        activateProduct(productId, videoId, documentId, signId, onComplete),
      ),
    clearPDF: () => dispatch(clearPDF()),
    sendSignByVoice: payload => dispatch(sendSignByVoice(payload)),

    loadPDFTerms: (onboardingId, origin, templateName) =>
      dispatch(loadTermsPDF(onboardingId, origin, templateName)),
    clearLinkSign: () => dispatch({type: SET_DATA_CLEAN}),
    getBannerActive: () => dispatch(getBannerActive()),
    updateImgOnboardingCurrent: (
      imageName,
      canvasSign,
      id,
      origin,
      template,
      onCompleted,
    ) =>
      dispatch(
        updateImgOnboardingCurrent(
          imageName,
          canvasSign,
          id,
          origin,
          template,
          onCompleted,
        ),
      ),
    checkStep: (step, goToStep) => dispatch(checkStep(step, goToStep)),
  }
}

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(SignPDFDefault),
)
