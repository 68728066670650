import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    isAndroid,
    isChrome,
    isEdge,
    isFirefox,
    isIOS,
    isMobile,
    isSafari,
} from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CMFLogoWhite from 'src/assets/images/logos/cmf-logo-white.svg';
import NewHomeImageMobile from 'src/assets/images/onboarding/new-home-image-mobile.webp';
import NewHomeImage from 'src/assets/images/onboarding/new-home-image.webp';
import NewHomeLogos from 'src/assets/images/onboarding/onboarding-new-home-logos.svg';
import { BreakPointTheme } from 'src/theme/breakpoints';
import styled from 'styled-components';
import { setStep } from '../../store/actions/Steps.Action';
import { CreateOnboardingCrePre } from '../../store/actions/requestCard.actions';
import ModalPersonalData from './Modals/ModalPersonalData';
import { SCREENS } from './screens';

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  minHeight: '100vh',
  flex: 1,
  background:
    'linear-gradient(to left bottom, #53a600, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18, #026e18ed, #53a600)',
}));

const DivWelcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-heigth: 100vh;
  flex: 1;
  align-items: center;
  margin: 10vh 0px 0px 0px;

  @media (min-width: 1024px) {
    margin: 20vh 0px 0px 0px;
  }
`;

const TitleSpan = styled.span`
  font-size: 26px;
  text-align: center;
  color: white;
  font-weight: 500;
  line-height: 32px;
  width: 90%;

  @media (min-width: 340px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (min-width: 400px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 52px;
  }
`;

const SubtitleSpan = styled.span`
  width: 95%;
  color: white;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-self: center;
  justify-self: center;
  justify-content: center;

  @media (min-width: 400px) {
    font-size: 14px;
  }

  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

const LogosDiv = styled.div`
  background-color: white;
  height: 50px;
  border-radius: 10px;
  padding: 0px 15px;
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 20px 0px @media (min-width: 540px) {
    padding: 15px 35px;
    height: 65px;
    margin: 10px 0px 30px 0px;
  }

  @media (min-width: 1024px) {
    margin: 15px 0px 50px 0px;
  }
`;

const RequirementsDiv = styled.div`
  color: white;
  width: 90%;
  max-width: 600px;
  background-color: #0000004d;
  padding: 15px;
  border-radius: 10px;
  font-size: 12px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (min-width: 540px) {
    font-size: 14px;
  }

  @media (min-height: 768px) {
    margin-top: auto;
    margin-bottom: 45px;
  }
`;

const ResponsiveMobileImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ResponsiveMobileImage = styled.img`
  width: 90%;
  max-width: 320px;
`;

const ResponsiveDesktopImage = styled.div`
  display: none;
  width: 50%;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 1024px) {
    display: flex;
    max-height: 100vh;
  }
`;

const WelcomeButton = styled.button`
  border: #53a600 1px solid;
  background-color: white;
  width: 90%;
  max-width: 240px;
  min-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  color: #026e18;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 400px) {
    min-height: 50px;
    height: 50px;
    font-size: 16px;
    max-width: 320px;
  }
`;

export const ContainerModal = styled('div')(() => ({
  fontFamily: 'Red Hat Text',
  backgroundColor: '#fff',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  width: '90%',
  height: 'auto',
  borderRadius: '15px',
  padding: '30px 0px',
  [BreakPointTheme.breakpoints.up('sm1')]: {
    width: '60%',
    height: 'auto',
  },
}));
export const ContainerData = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Red Hat Text',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 10px',
}));

export const DataWebView = styled('h1')(() => ({
  fontFamily: 'Red Hat Text',
  color: '#000',
  fontSize: 'clamp(14px, 1vw, 20px)',
  marginBottom: 0,
  fontWeight: '500',
}));

const WelcomeTDC = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openModalWebView, setOpenModalWebView] = useState(false);
  const [visibleWebViewIOS, setVisibleWebViewIOS] = useState(false);
  const [linkReferral, setLink] = useState(false);
  const modalPersonaDataRef = useRef();

  useEffect(() => {
    dispatch({type: 'RESET_ONBOARDING_PHONE'});
    dispatch({type: 'CLEAN_DIALOG'});
    dispatch({type: 'SET_URL_PATH', payload: window.location.pathname});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLink(window.location.href);
    const url = window.location.href;
    if (isMobile && isIOS && !isSafari && !isFirefox && !isChrome && !isEdge) {
      window.location.href = `googlechrome://${url.replace(
        /^https?:\/\//,
        '',
      )}`;
      setOpenModalWebView(true);
      setVisibleWebViewIOS(true);
      setValid(false);
    } else if (isMobile && isAndroid && !isFirefox && !isChrome && !isEdge) {
      window.location.href = `intent://${url.replace(
        /^https?:\/\//,
        '',
      )}#Intent;scheme=https;package=com.android.chrome;end`;
      setOpenModalWebView(true);
      setValid(false);
    }
  }, [openModalWebView]);

  useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = `https://${window.location.host}${location.pathname}${location.search}`;
    }
  }, [location]);

  const handleClick = () => {
    setLoading(true);
    dispatch(CreateOnboardingCrePre('', onSuccess));
  };

  const onSuccess = success => {
    setLoading(false);
    modalPersonaDataRef.current.show();
  };

  const onConfirm = () => {
    dispatch(setStep(SCREENS.Recommendation));
    props.props.goToStep(SCREENS.Recommendation);
  };

  const onReject = () => {
    props.props.goToStep(SCREENS.ErrorScreen);
  };

  const copyLinkURL = () => {
    navigator.clipboard.writeText(linkReferral);
    NotificationManager.success('Enlace copiado correctamente');
  };

  return (
    <>
      <Container id="Container">
        <DivWelcome>
          <TitleSpan>Estás a un paso de</TitleSpan>
          <TitleSpan>construir tus sueños</TitleSpan>

          <span style={{margin: '15px 0px'}}></span>

          <SubtitleSpan>
            Convierte tus compras hasta en 36 cuotas con
          </SubtitleSpan>
          <SubtitleSpan>
            la tarjeta digital{' '}
            <img
              src={CMFLogoWhite}
              alt="CMF White"
              style={{margin: '0px 6px'}}
            />{' '}
            en las sucursales
          </SubtitleSpan>

          <LogosDiv>
            <img
              src={NewHomeLogos}
              alt=""
              style={{objectFit: 'contain', height: '100%'}}
            />
          </LogosDiv>

          <WelcomeButton
            onClick={handleClick}
            disabled={!valid || openModalWebView || loading}>
            {!loading ? (
              'Solicitar aquí'
            ) : (
              <CircularProgress
                color="inherit"
                style={{width: 20, height: 20}}
              />
            )}
          </WelcomeButton>
          <ResponsiveMobileImageContainer>
            <ResponsiveMobileImage
              src={NewHomeImageMobile}
              alt="Home"></ResponsiveMobileImage>
          </ResponsiveMobileImageContainer>

          <RequirementsDiv>
            <span style={{fontWeight: '500'}}>Recuerda:</span> Tener a mano tu
            cédula y asegúrate de que esté limpia - Asegúrate de tener acceso a
            tu celular - Asegúrate de tener acceso a tu correo electrónico
          </RequirementsDiv>
        </DivWelcome>

        <ResponsiveDesktopImage>
          <img
            src={NewHomeImage}
            alt="Home"
            style={{objectFit: 'contain', height: '100%'}}
          />
        </ResponsiveDesktopImage>
      </Container>
      <ModalPersonalData
        ref={modalPersonaDataRef}
        onConfirm={onConfirm}
        onClose={onReject}
      />
      {openModalWebView && (
        <Modal
          disableEscapeKeyDown={true}
          open={openModalWebView}
          // onClose={() => setOpenModalWebView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <ContainerModal>
            <WarningIcon sx={{color: '#ffe600', fontSize: '5rem'}} />
            <ContainerData>
              {visibleWebViewIOS ? (
                <DataWebView>
                  Identificamos que estás usando un navegador no compatible. Te
                  invitamos a copiar el enlace y usarlo desde tu navegador{' '}
                  <strong>Safari</strong> o <strong>Chrome</strong> para
                  solicitar tu tarjeta digital CMF
                </DataWebView>
              ) : (
                <DataWebView>
                  Identificamos que estás usando un navegador no compatible. Te
                  invitamos a copiar el enlace y usarlo desde tu navegador{' '}
                  <strong>Chrome</strong> o <strong>Firefox</strong> para
                  solicitar tu tarjeta digital CMF
                </DataWebView>
              )}
            </ContainerData>
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '200px',
                backgroundColor: 'var(--specific-light-background-20)',
                flexDirection: 'row',
              }}>
              <Button
                sx={{
                  borderRadius: '100px',
                  width: '10rem !important',
                  height: '2.25rem',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 2px',
                  boxSizing: 'border-box',
                  gap: '8px',
                  textAlign: 'left',
                  fontSize: 'var(--detail-m-size)',
                }}
                variant="contained"
                onClick={copyLinkURL}>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Red Hat Text',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '22px /* 157.143% */',
                    letterSpacing: '-0.14px',
                  }}>
                  Copiar Enlace
                </Typography>
              </Button>
            </Box>
          </ContainerModal>
        </Modal>
      )}
    </>
  );
};

export default WelcomeTDC;
