import style from './ChubbScreenPaymentInfo.module.css'
import { Fragment } from 'react'

const TEXT = "los clientes mayores de 65 años de edad no están cubiertos por este seguro.";

/**
 * ChubbScreenPaymentInfo Component displays payment information for Chubb insurance.
 * 
 * This component renders payment information for Chubb insurance, including important 
 * text and price details. It provides different layouts for mobile and desktop views.
 * 
 * @component
 * @example
 * return (
 *   <ChubbScreenPaymentInfo />
 * )
 */
const ChubbScreenPaymentInfo = () => {
    return (
        <Fragment>
            <div className={style.mobileTextContainer}>
                <span>Importante:</span>
                <span className={style.mobileTextSecond}>{TEXT}</span>
            </div>
            <div className={style.desktopContainer}>
                <div className={style.desktopSubContainer}>
                    <span className={style.desktopContainer__text}>
                        <span >Importante:</span>
                        <span>{TEXT}</span>
                    </span>
                    <span className={style.desktopContainer__price}>
                        <span>Solo pagas </span>
                        <span> $4 por mes</span>
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default ChubbScreenPaymentInfo;