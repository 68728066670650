import {styled} from '@material-ui/core/styles'
import {Box, CircularProgress, Typography} from '@mui/material'
import React from 'react'
import {FONT_FAMILY, FONT_SIZE} from 'src/theme/constants'
import logoCMFWhite from '../../../assets/images/cmf_linea/logoCMF_White.svg'
const Cicular = styled(CircularProgress)(({theme}) => ({
  color: theme.palette.common.white,
  width: '28px !important',
  position: 'absolute',
  '& .MuiCircularProgress': {
    backgroundSize: '100% 100%',
    border: '4px solid',
    borderColor: '#d0d5dd29',
    borderRadius: '14.25px',
    height: '28px',
    left: '1px',
    position: 'relative',
    top: '1px',
    width: '28px',
  },
}))

const Container = styled(Box)(({imageUrl, theme}) => ({
  alignItems: 'center',
  backgroundImage: `url(${imageUrl})`,
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px 0',
  cursor: 'pointer',
  width: '100%',
}))
const Card = styled('div')(() => ({
  display: 'flex',
  width: '95%',
  flexDirection: 'column',
  alignContent: 'space-around',
  background: 'transparent',
  height: '116px',
  justifyContent: 'space-around',
}))
const ContainerHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'flex-end',
  justifyContent: 'space-between',
}))
const ContentImage = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
}))
const ContentMoney = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))
const TextMoney = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const ContainerData = styled('div')(({theme}) => ({
  display: 'flex',
  '& p': {
    margin: '0',
    padding: '0',
    fontFamily: FONT_FAMILY.main,
    fontSize: FONT_SIZE.md,
    color: theme.palette.common.white,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.16px',
  },
  '& a': {margin: '0', padding: '0'},

  justifyContent: 'space-between',
}))

const CardContainer = ({imageUrl, onClick, porcentaje, money, dataCard}) => {
  return (
    <>
      <Container imageUrl={imageUrl} onClick={onClick}>
        <Card>
          <ContainerHeader>
            <ContentImage>
              <img src={logoCMFWhite} alt='Logo CMF White'></img>
            </ContentImage>
            <ContentMoney>
              <Box
                className="spinnerCard"
                sx={{position: 'relative', display: 'inline-flex'}}>
                <Cicular
                  variant="determinate"
                  thickness={6}
                  value={porcentaje}
                />
                <Cicular
                  style={{
                    color: '#D0D5DD29',
                  }}
                  variant="determinate"
                  thickness={6}
                  value={100}
                />
              </Box>
              <TextMoney>
                <Typography variant="moneyCard" sx={{color: '#FFF'}}>
                  {money}
                </Typography>
                <Typography variant="textCard" sx={{color: '#FFFFFF8A'}}>
                  Disponible
                </Typography>
              </TextMoney>
            </ContentMoney>
          </ContainerHeader>
          <ContainerData>
            {/* <Typography color="#FFF" variant="H4">
              Tarjeta de Crédito
            </Typography> */}
            <Typography
              color="#FFF"
              variant="textCard"
              sx={{
                fontSize: '18px',
                marginRight: '1.8rem',
              }}>
              {dataCard}
            </Typography>
          </ContainerData>
        </Card>
      </Container>
    </>
  )
}

export default CardContainer
