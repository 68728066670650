import style from './ChubbScreenButtons.module.css'

/**
 * ChubbScreenButtons Component renders buttons for Chubb insurance screen.
 * 
 * This component renders two buttons for user interaction on the Chubb insurance screen:
 * - "Si, lo quiero" button: Indicates user acceptance.
 * - "No en este momento" button: Indicates user rejection or cancellation.
 * 
 * @component
 * @param {function} onNext - Function to handle click event on the "Si, lo quiero" button.
 * @param {function} onCancel - Function to handle click event on the "No en este momento" button.
 * @example
 * return (
 *   <ChubbScreenButtons
 *      onNext={() => handleNext()}
 *      onCancel={() => handleCancel()}
 *   />
 * )
 */
const ChubbScreenButtons = ({ onNext = () => { }, onCancel = () => { } }) => {
    return (
        <div className={style.mainContainer}>
            <button id='yes-button-chubb' className="greenBtnBg" onClick={onNext}>
                Si, lo quiero
            </button>
            <button id='no-button-chubb' onClick={onCancel}>
                No en este momento
            </button>
        </div>
    )
}

export default ChubbScreenButtons;