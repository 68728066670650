import React from 'react'

export function Button({
  label = '',
  textColor = 'text-btnPrimary',
  bg = 'bg-white',
  className,
  py,
  borderRadius = 'rounded-full',
  onClick = () => {},
}) {
  const defaultClasses = [
    borderRadius,
    'font-normal',
    textColor,
    bg,
    bg ? '' : 'border-2 border-[#53A600]',
    'w-[270px]',
    py || 'py-3',
    'lg:text-lg text-xl lg:font-normal font-semibold',
    'cursor-pointer',
  ]
  const allClasses = [...defaultClasses, className].join(' ')

  return (
    <div className="w-[270px] lg:py-0 py-4">
      <button className={allClasses} onClick={onClick}>{label}</button>
    </div>
  )
}
