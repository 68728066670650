import { styled } from '@material-ui/core/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cmf_Base, PointsApi } from 'src/Data/Api';
import SwipeableTemporaryDrawer from 'src/components/Drawers/SwipeableTemporaryDrawer';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  CantidadTotalEnPorcentaje,
  getCurrent,
  ocultarNumero,
} from 'src/utils/Functions';
import formats from 'src/utils/formats';
import { ContainerData, ContainerMaterial } from '../Pages/ContainerHome';
import CardContainer from './CardContainer';
function createData(details, data) {
  return { details, data };
}

const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const TableContent = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  margin: '16px 0px',
  borderRadius: '20px',
  [BreakPointTheme.breakpoints.only('xxs')]: {
    width: '97%',
    margin: '5px',
  },
  [BreakPointTheme.breakpoints.only('sm')]: {
    width: '80%',
    margin: '5px',
  },
  [BreakPointTheme.breakpoints.only('md')]: {
    width: '80%',
  },
  [BreakPointTheme.breakpoints.only('lg')]: {
    width: '75%',
  },
  [BreakPointTheme.breakpoints.between('xl', 'xxl')]: {
    width: '80%',
  },
}));
const ContainerBody = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  // margin: '16px',
  [BreakPointTheme.breakpoints.only('xxs')]: {
    width: '97%',
    margin: '5px',
  },
  [BreakPointTheme.breakpoints.only('sm')]: {
    width: '80%',
    margin: '5px',
  },
  [BreakPointTheme.breakpoints.only('md')]: {
    width: '80%',
  },
  [BreakPointTheme.breakpoints.only('lg')]: {
    width: '75%',
  },
  [BreakPointTheme.breakpoints.between('xl', 'xxl')]: {
    width: '80%',
  },
}));

export const ContainerTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '@media only screen': {
    [BreakPointTheme.breakpoints.between('xxs', 'sm')]: {
      width: '90%',
      marginBottom: '0.8rem',
    },
    [BreakPointTheme.breakpoints.only('md')]: {
      width: '70%',
    },
    [BreakPointTheme.breakpoints.only('lg')]: {
      width: '65%',
    },
    [BreakPointTheme.breakpoints.between('xl', 'xxl')]: {
      width: '60%',
    },
  },
}));
const TableMain = styled(Table)(({ imageUrl, theme }) => ({
  borderRadius: '20px',
  cursor: 'pointer',
  width: '100%',
}));
const DetailsCard = ({ CuentaDesen, DisponibleML, LimCreditoML }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [datas, setDatas] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedProduct } = useSelector(state => state.ActiveProductReducer);
  const { CurrentUser } = useSelector(state => state.AuthenticationReducer);
  let productAccount = encodeURIComponent(selectedProduct?.productAccount);
  const imageCardBackground =
    selectedProduct.customerAccountCards[0].cardImage;

  const { isCorporate } = useSelector(
    state => state.ActiveProductReducer.selectedProduct,
  );
  async function fetchData() {
    setLoading(true);
    setError(false);

    try {
      const ciclo = getCurrent(selectedProduct.cycle);
      const Mes = ciclo.Mes;
      const Anyo = ciclo.Anyo;

      const urls = [
        `${Cmf_Base}${PointsApi}?anyo=${Anyo}&Mes=${Mes}&Cuenta=${productAccount}&CreatedBy=${selectedProduct?.customerId}`,
        `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${productAccount}&CreatedBy=${selectedProduct?.customerId}`,
      ];

      const responses = await Promise.all(
        urls.map(url =>
          fetch(url, {
            headers: {
              Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
            },
          }),
        ),
      );

      const jsonData = await Promise.all(
        responses.map(response => {
          if (!response.ok) {
            throw new Error(
              `Solicitud fallida con código de estado ${response.status}`,
            );
          }
          return response.json();
        }),
      );

      setData(JSON.parse(jsonData[0]?.jsonAnswer));
      setDatas(JSON.parse(jsonData[1]?.jsonAnswer));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // MovementData();
  }, []);
  const rows = [
    createData('Pago Mínimo', `$${datas?.PagoMinimoML?.toFixed(2)}`),
    createData('Saldo Total Adeudado', `$${datas?.PagocontadoML?.toFixed(2)}`),
    createData(
      'Fecha Máxima de Pago',
      moment(data?.FecMaxPago, 'DD/MM/YYYY').format('DD/MM/YYYY'),
    ),
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (isCorporate) {
      navigate('/home');
    }
  }, [selectedProduct]);

  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData>
        <DetailsContainer>
          <ContainerTitle>
            <Typography
              variant="H5"
              gutterBottom
              style={{
                margin: '0px 15px',
                color: '#101828CC',
              }}>
              Pagar tarjeta cmf
            </Typography>
          </ContainerTitle>
          {loading ? (
            <Skeleton
              variant="text"
              width="100%"
              height={200}
              animation="wave"
            />
          ) : (
            <ContainerBody>
              <CardContainer
                imageUrl={imageCardBackground}
                onClick=""
                money={formats.currency(datas?.DisponibleML)}
                porcentaje={CantidadTotalEnPorcentaje(
                  datas?.DisponibleML,
                  datas?.LimCreditoML,
                )}
                dataCard={`${ocultarNumero(datas?.CuentaDesen)}`}
              />
            </ContainerBody>
          )}

          <ContainerTitle>
            <Typography
              variant="H5"
              gutterBottom
              sx={{
                margin: '5px 15px',
                color: '#101828CC',
                [BreakPointTheme.breakpoints.down('sm')]: {
                  marginTop: '0.8rem',
                },
              }}>
              Detalle de pago
            </Typography>
          </ContainerTitle>
          <TableContent component={Paper}>
            {error && (
              <Paper
                elevation={3}
                style={{ padding: '20px', textAlign: 'center' }}>
                <Skeleton
                  variant="text"
                  width="100%"
                  height={40}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="100%"
                  height={40}
                  animation="wave"
                />
                <Button
                  variant="contained"
                  data-testid="reintentar"
                  startIcon={<ReplayIcon />}
                  onClick={fetchData}>
                  Reintentar
                </Button>
              </Paper>
            )}
            {loading ? (
              <Skeleton variant="rectangular" width="100%">
                <div style={{ height: '15rem' }} />
              </Skeleton>
            ) : (
              <TableMain aria-label="simple table">
                <TableBody>
                  {data &&
                    rows.map(row => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left" style={{ color: '#101828A3' }}>
                          <Typography variant="H4">{row.details}</Typography>
                        </TableCell>
                        <TableCell align="right" style={{ color: '#026E18' }}>
                          {row.data}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TableMain>
            )}
          </TableContent>
          {!error && (
            <ContainerBody>
              <SwipeableTemporaryDrawer
                icon="not"
                state="default"
                texto="Hacer Pago"
                variant="contained"
                types="Button"
                payMin={datas?.PagoMinimoML}
                pagoContado={datas?.PagocontadoML}
                loading={loading}
              />
            </ContainerBody>
          )}
        </DetailsContainer>
      </ContainerData>
    </ContainerMaterial>
  );
};

export default DetailsCard;
