import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import Exclamation from 'src/assets/images/onboardingCredito/exclamation.svg';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonModals from 'src/components/Button/ButtonModals';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import ElectronicaID, {
  ELECTRONIC_SCAN_TYPE,
} from 'src/components/ElectronicaID';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import CMFLogo from 'src/components/Logos/CMF';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import {
  editFormRequest,
  setBase64,
  setRequestVideoId,
  updateInfoScanBiometric,
  verifyBatchPre,
} from 'src/store/actions/requestCard.actions';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { APP } from 'src/utils/Constants';
import { OnCompleteVideo } from 'src/utils/onCompleteVideo';
import * as Yup from 'yup';
import { formFields } from '../CardRequest/FormRequest.config';
import { VideoFake } from './fake/videoComplete';
import SCREENS from './screens';
import ModalIdentityConfirm from './Modals/ModalIdentityConfirm';
const regex = /^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/i;

const idValidationSchema = Yup.object().shape({
  identityNumbers: Yup.string()
    .matches(regex, 'Formato de cédula panameña no válido')
    .required('Cédula es requerida'),
});

const formDataRequestSchema = Yup.object({
  IDExpirationDate: Yup.string()
    .min(1)
    .required(),
  backIdentityImage: Yup.string()
    .min(1)
    .required(),
  birthdate: Yup.string()
    .min(1)
    .required(),
  countryOfBirth: Yup.string()
    .min(1)
    .required(),
  faceImage: Yup.string()
    .min(1)
    .required(),
  firstName: Yup.string()
    .min(1)
    .required(),
  frontIdentityImage: Yup.string()
    .min(1)
    .required(),
  gender: Yup.number().required(),
  identity: Yup.string()
    .min(1)
    .required(),
  isDocumentNotExpired: Yup.boolean().required(),
  lastName: Yup.string()
    .min(1)
    .required(),
  // nationality: Yup.string().min(1).required(),
  // secondLastName: Yup.string().min(1).optional(),
  // secondName: Yup.string().min(1).optional(),
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
}));

const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '33%',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  margin: 'auto',
  width: '50%',

  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '55%',
    height: 'initial',
    gap: '10px',
  },

  [BreakPointTheme.breakpoints.down('bg')]: {
    width: '70%',
    height: 'initial',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto 2rem auto',
    width: '90%',
    height: 'initial',
    gap: '1rem',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    margin: '2.5rem auto 2rem auto',
    width: '90%',
    height: 'initial',
    gap: '0.7rem',
  },
}));

const InputMain = styled(TextField)(() => ({
  textTransform: 'uppercase',
  width: '30vw',
  fontSize: '20px',
  color: 'white !important',
  background: '#FFF',
  borderRadius: '15px',
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '31vw',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '31vw',
  },
  [BreakPointTheme.breakpoints.down('bg')]: {
    width: '50vw',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}));

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));

const ScanID = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const countriesData = useSelector(
    state => state.RequestCardReducer.countriesBirthTranslate,
  );
  const nationalitiesCatalog = useSelector(
    state => state.RequestCardReducer.nationalitiesTranslate,
  );

  const mapeo_nacionalidades = useSelector(
    state => state.RequestCardReducer.mapeo_nacionalidades,
  );
  const stopPrepaid = useSelector(
    state => state.RequestCardReducer.stopPrepaid,
  );
  const dispatch = useDispatch();
  const [nationality, setNationality] = useState('');
  const [visible, setVisible] = useState(false);
  const [failIdentity, setFailIdentity] = useState(false);
  const [identityType, setIdentityType] = useState(null);
  const [initIdentity, setInitIdentity] = useState(null);
  const [identityNumbers, setIdentityNumbers] = useState(null);
  const [isFake] = useState(APP.fakeScan);
  const [windowHeight, setWindowHeight] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [loadingUpt, setLoadingUpt] = useState(false);
  const [error, setError] = useState('');
  const [openValidateIdentity, setOpenVerifyIdentity] = useState(false);
  const searchValue = useCallback(value => {
    if (value === 'E') {
      return ELECTRONIC_SCAN_TYPE.CEDULA_EXTRANJERA;
    } else {
      return ELECTRONIC_SCAN_TYPE.CEDULA_NACIONAL;
    }
  });
  // Equivalent to componentDidMount
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    // The cleanup function, equivalent to componentWillUnmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means it only runs on mount and unmount

  const nextStep1 = () => {
    props.goToStep(SCREENS.ProductExist);
  };
  const nextStep8 = () => {
    props.goToStep(SCREENS.PrepagoRedirectApp);
  };
  const nextStep6 = () => {
    setVisible(false);
    props.goToStep(SCREENS.PrepagoProductExist);
  };
  const nextStep7 = () => {
    setVisible(false);
    props.goToStep(SCREENS.PrepagoProductStop);
  };
  const goToIdentityMissingFields = () =>
    props.goToStep(SCREENS.IdentityMissingFields);
  const prevStepInitial = () => {
    props.goToStep(SCREENS.ScanID);
  };
  const nextStep = () => {
    props.nextStep();
  };
  const handleResize = () => {
    setWindowHeight(window.innerHeight * 0.7);
    setWindowWidth(window.innerWidth * 0.9);
  };
  const OnCompleteVideoEID = async video => {
    let response = await OnCompleteVideo(
      video,
      nationalitiesCatalog,
      countriesData,
      initIdentity,
      mapeo_nacionalidades,
    );

    const isValidSchema = await formDataRequestSchema.isValid(
      response?.formDataRequest,
    );
    if (!isValidSchema) {
      goToIdentityMissingFields();
      return;
    }

    if (response?.formDataRequest?.nationality !== null) {
      dispatch(setRequestVideoId(response.video.id));
      dispatch(editFormRequest(response.formDataRequest));
      dispatch(setBase64(response.base64));
      dispatch(
        verifyBatchPre(
          response.personalNumber,
          onNext.bind(this),
          response.typeIdentity,
        ),
      );
    } else {
      setNationality(null);
      onNext(4);
    }
  };
  const onNext = async success => {
    switch (success) {
      case true:
        setVisible(false)
        setOpenVerifyIdentity(true)
        break
      case 2:
        setVisible(false)
        setOpenVerifyIdentity(true)
        break
      case 4:
        setFailIdentity(true)
        setOpenVerifyIdentity(false)
        setVisible(false)
        prevStepInitial()
        break
      case 5:
        setVisible(false);
        setFailIdentity(true);
        prevStepInitial();
        break;
      case 8:
        nextStep8();
        break;
      case false:
        nextStep1()
        break
      default:
        console.warn(`Ha ocurrido un error en el proceso de video`)
    }
  }
  const hideModalIdentity = () => {
    setOpenVerifyIdentity(false)
  }
  const onSuccess = success => {
    setLoadingUpt(false);
    switch (success) {
      case true:
        setVisible(true);
        if (isFake) {
          setTimeout(() => {
            if (stopPrepaid) return;
            simulateScan(identityNumbers.charAt(0));
          }, 6000);
        }
        break;
      case 2:
        nextStep6();
        break;
      case 3:
        nextStep7();
        break;
      default:
        console.warn(`Ha ocurrido un error en el proceso de video`);
    }
  };
  function renderModalFailEID() {
    const abrirCorreo = () => {
      const correo = 'servicioalcliente@cmf.com.pa';
      const subject = encodeURIComponent(
        'La nacionalidad no está en el catálogo',
      );
      const body = encodeURIComponent(`Hola servicio al cliente CMF,
  
  Mi nacionalidad no está en el catálogo y no puedo obtener la tarjeta digital CMF.
  
  Gracias`);

      window.open(`mailto:${correo}?subject=${subject}&body=${body}`);
    };
    const handleEmail = () => {
      if (nationality !== null) {
        setFailIdentity(false);
        setVisible(true);
      } else {
        abrirCorreo();
      }
    };
    return (
      <Modal
        center={true}
        open={failIdentity}
        destroyOnClose={true}
        onOk={() => {}}
        classNames={{
          modal: 'fail__identity',
        }}
        showCloseIcon={false}
        okButtonProps={{disabled: true}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px', // This sets the margin-top to 0 pixels
            '@media (min-width: 768px)': {
              // This is an example breakpoint for 'md:' in Tailwind CSS
              marginTop: '0px',
            },
            [BreakPointTheme.breakpoints.down('sm')]: {
              height: 'auto%',
              justifyContent: 'start',
              marginTop: '13vh',
              // justifyContent: 'space-evenly',
            },
          }}
          // style={{margin: '1rem 0 .3rem 0'}}
        >
          <div className="icon__info">
            <img src={Exclamation} alt="icono de información" />
          </div>
          <br></br>
          <TitleResponsiveOnboarding
            minSize={25}
            maxSize={30}
            text={
              nationality === null
                ? 'La nacionalidad capturada no se encuentra identificada en nuestro catálogo.'
                : 'El número de cédula ingresado no coincide con el número capturado en el proceso de validación'
            }
            color="#011E41"
            fontFamily="Red Hat Text"
            style={{
              [BreakPointTheme.breakpoints.up('sm')]: {
                lineHeight: '2rem',
              },
              [BreakPointTheme.breakpoints.down('sm')]: {
                lineHeight: '1.8rem',
              },
            }}
          />
          <br />
          <SubtitleResponsiveOnboarding
            minSize={18}
            maxSize={25}
            text={
              nationality === null
                ? 'Envíanos un correo con este detalle dando click al siguiente botón.'
                : 'Por favor intenta de nuevo el proceso de validación'
            }
            color="#000000"
            fontFamily="Red Hat Text"
            textAlign="center"
          />
          <Grid
            container
            spacing={2}
            sx={{
              [BreakPointTheme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
              },
            }}
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'center'}>
            <Grid item xs={'auto'}>
              <ButtonModals
                variant="onboardingOutline"
                onClick={() => {
                  setFailIdentity(false);
                }}
                fullWidth>
                Cancelar
              </ButtonModals>
            </Grid>
            <Grid item xs={'auto'}>
              <ButtonModals
                variant="onboarding"
                fullWidth
                onClick={handleEmail}>
                {nationality === null ? 'Reporta a CMF' : 'Reintentar'}
              </ButtonModals>
            </Grid>
          </Grid>
          <Typography
            textType="paragraphs"
            style={{
              textAlign: 'center',
              fontSize: '0.8rem',
              fontFamily: 'Red Hat Text',
              lineHeight: 1.2,
              marginTop: 35,
              marginBottom: 0,
            }}>
            <strong>¿Necesitas asistencia en tu proceso?</strong>
            <br />
            contáctanos a servicioalcliente@cmf.com.pa
          </Typography>
        </Box>
      </Modal>
    );
  }
  const simulateScan = async initDocument => {
    if (isFake) {
      let response = await OnCompleteVideo(
        VideoFake,
        nationalitiesCatalog,
        countriesData,
        initDocument,
        mapeo_nacionalidades,
      );

      const isValidSchema = await formDataRequestSchema.isValid(
        response?.formDataRequest,
      );

      const script = document.getElementById('myDynamicScript');
      if (script) {
        script.parentNode.removeChild(script);
      }

      if (!isValidSchema) {
        goToIdentityMissingFields();
        return;
      }

      if (response?.formDataRequest?.nationality !== null) {
        dispatch(editFormRequest(response.formDataRequest));
        dispatch(setRequestVideoId(response.video.id));
        dispatch(setBase64(response.base64));
        dispatch(
          verifyBatchPre(
            response.personalNumber,
            onNext,
            response.typeIdentity,
          ),
        );
      } else {
        setNationality(null);
        onNext(4);
      }
    }
  };
  const handleClick = () => {
    if (isFake) {
      validateIdNumber(identityNumbers);
      let numeroCedula = identityNumbers;
      setInitIdentity(numeroCedula.charAt(0));
      setIdentityType(searchValue(numeroCedula.charAt(0)));
      setLoadingUpt(true);
      dispatch({type: 'CEDULA_NUMBER', payload: numeroCedula});
      dispatch(updateInfoScanBiometric(onSuccess));
    } else {
      validateIdNumber(identityNumbers);
      let numeroCedula = identityNumbers;
      let format = /^P$|^(?:PE|E|N|[23456789]|[23456789](?:A|P)?|1[0123]?|1[0123]?(?:A|P)?)$|^(?:PE|E|N|[23456789]|[23456789](?:AV|PI)?|1[0123]?|1[0123]?(?:AV|PI)?)-?$|^(?:PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(?:\d{1,4})-?$|^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/i;
      const message = formFields.identity.rules[1].message;
      if (!error) {
        if (format.test(numeroCedula)) {
          if (numeroCedula != null) {
            setInitIdentity(numeroCedula.charAt(0));
            setIdentityType(searchValue(numeroCedula.charAt(0)));
            setLoadingUpt(true);

            // Replace the following Redux actions with the corresponding ones in your app
            dispatch({type: 'CEDULA_NUMBER', payload: numeroCedula});
            dispatch(updateInfoScanBiometric(onSuccess));
          }
        } else {
          return;
        }
      } else {
        NotificationManager.error(message);
      }
    }
  };
  const handleChange = e => {
    return setIdentityNumbers(e.target.value.toUpperCase());
  };
  /**
   * Validates Panamanian identity number using a regular expression.
   *
   * @param {string} identityNumber - The identity number to be validated.
   * @returns {boolean} True if the identity number is valid, false otherwise.
   */
  function validatePanamanianIdentityNumber(identityNumber) {
    return regex.test(identityNumber);
  }

  const isValid = validatePanamanianIdentityNumber(identityNumbers);

  const renderModal = () => {
    return (
      <BootstrapDialog
        className="container-scan"
        sx={{
          '& .MuiPaper-root': {
            width: !isMobile ? windowWidth : null,
            // [BreakPointTheme.breakpoints.down('sm')]: {
            //   height: '60%',
            // },
            // [BreakPointTheme.breakpoints.up('sm')]: {
            //   height: '50%',
            // },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={visible}>
        <Box
          sx={{
            background: '#399001',
          }}>
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              fontWeight: '300 !important',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '90%',
              },
              color: '#FFFFFF',
              font: 'normal normal normal 18px/21px Red Hat Text',
            }}
            id="customized-dialog-title">
            Acerca tu cédula de identidad a la pantalla para ser escaneada
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setVisible(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              [BreakPointTheme.breakpoints.down('sm')]: {
                paddingTop: '16px',
              },
            }}>
            <CloseIcon
              sx={{
                background: '#FFFFFF',
                color: '#399001',
                borderRadius: '1rem',
                width: '1.5rem',
                height: '1.5rem',
              }}
            />
          </IconButton>
        </Box>
        <DialogContent dividers className="scan-devices-container">
          <ElectronicaID
            visible={visible}
            keyAuth={APP.electronicId.tokenAuth}
            documentType={identityType}
            onComplete={OnCompleteVideoEID.bind(this)}
            height={windowHeight < 600 ? windowHeight : 600}
            width={windowWidth}
          />
        </DialogContent>
      </BootstrapDialog>
    );
  };
  const handleBlur = () => {
    validateIdNumber(identityNumbers);
  };
  const validateIdNumber = value => {
    idValidationSchema
      .validate({identityNumbers: value})
      .then(() => setError(''))
      .catch(err => setError(err.errors[0]));
  };

  return (
    <Container>
      {renderModalFailEID()}
      {renderModal()}
      <ModalIdentityConfirm
        open={openValidateIdentity}
        onClose={hideModalIdentity}
        props={props}
      />
      <WrapperSide background="rgb(255, 255, 255)" />
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          background:
            'radial-gradient(circle at top left, #024114, transparent 30%),radial-gradient(circle at top right, #017617, transparent 30%),radial-gradient(circle at bottom left, #026D17, transparent 30%),radial-gradient(circle at bottom right, #026D17, transparent 30%),   linear-gradient(135deg, #026D17 0%, #0B7021 20%, #026C18 40%, #015517 60%, #024315 80%, #025918 100%)',
        }}>
        <center>
          <CMFLogo />
        </center>
        <ContainerInfo>
          <TitleResponsiveOnboarding
            minSize={35}
            maxSize={65}
            text="Verificación de identidad"
            color="#fff"
            fontFamily="Red Hat Text"
          />
          <br />
          <SubtitleResponsiveOnboarding
            minSize={22}
            maxSize={35}
            text="Ingresa tu número de cédula como en tu documento, incluyendo los guiones"
            color="#fff"
            fontFamily="Red Hat Text"
          />
          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <InputMain
              placeholder={formFields.identity.placeholder}
              variant="outlined"
              value={identityNumbers}
              onChange={e => {
                return handleChange(e);
              }}
              InputProps={{
                inputProps: {
                  'data-testid': 'identity-number',
                },
              }}
              onBlur={handleBlur}
              error={!!error}
              rules={formFields.identity?.rules}
            />
            {error && (
              <p
                style={{
                  color: '#fff',
                }}>
                {error}
              </p>
            )}
          </FormControl>
          {/* </FormMain> */}
        </ContainerInfo>
        <FooterOnboar
          back={
            props.canReturn ? (
              <ButtonNextOnboarding
                onClick={() => {
                  props.goToStep(SCREENS.VerifyMailPhone);
                }}
                style={{
                  background: '#ffffff 0% 0% no-repeat padding-box !important',
                  color: '#53A600 !important',
                  border: '2px solid #666B76',
                }}>
                Volver
              </ButtonNextOnboarding>
            ) : null
          }
          next={
            <ButtonNextOnboarding
              style={{
                background: isValid
                  ? 'transparent linear-gradient(270deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat !important'
                  : '#D0D5DD 0% 0% no-repeat padding-box !important',
                color: isValid ? '#53A600 !important' : '#ffffff !important',
                border: isValid && '2px solid #666B76',
              }}
              disabled={!isValid}
              variant="onboardingOutline"
              data-testid="btn-identity"
              onClick={() => {
                if (!isValid) return;
                handleClick();
              }}
              loading={loadingUpt}>
              Continuar
            </ButtonNextOnboarding>
          }
          direction="end"
        />
      </Wrapper>
    </Container>
  );
};
export default ScanID;
