import bannerChubb from 'src/assets/images/onboardingCredito/chubb/bannerChubb.png'
import logoChubb from 'src/assets/images/onboardingCredito/chubb/logoChubb.png'
import style from './ChubbScreenHeader.module.css'

/**
 * ChubbScreenHeader Component displays the header for the Chubb insurance screen.
 * 
 * This component renders the header section for the Chubb insurance screen,
 * including a banner image, Chubb logo, introductory text, and a separator.
 * 
 * @component
 * @example
 * return (
 *   <ChubbScreenHeader />
 * )
 */
const ChubbScreenHeader = () => {
    return (
        <header className={style.mainContainer}>
            <img src={bannerChubb} alt="Banner Chubb" className={style.bannerImage} />
            <img src={logoChubb} alt="Logo Chubb" className={style.chubbImage} />
            <span className={style.text}>Te presentamos el seguro de Accidentes Personales</span>
            <div className={style.separator}></div>
        </header>
    )
}

export default ChubbScreenHeader;