import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import logger from 'redux-logger';
import sessionStorage from 'redux-persist/lib/storage/session';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { ENVIRONMENT } from '../store/actions/API';
import Reducers from '../store/reducers/root';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: '@cmfAppKeyPrimary',
  storage: sessionStorage,
  // timeout: 0,
  blacklist: [
    'RequestCardReducer',
    'PhoneReducer',
    'Phone',
    'KOReducer',
    'productReducer',
    'ActiveProductReducer',
  ],
};

let middlewares = [thunk, promise];

if (ENVIRONMENT !== 'prod') middlewares.push(logger);
const persistedReducer = persistReducer(persistConfig, Reducers);

const configureStore = () => {
  return createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  // compose(applyMiddleware,composeEnhancers(...middlewares)),
};

const store = configureStore();
const persistor = persistStore(store);
export { persistor, store };

