import style from './ChubbScreenTerms.module.css'

/**
 * ChubbScreenTerms Component displays terms and conditions for Chubb insurance.
 * 
 * This component renders a disclaimer regarding Chubb insurance terms and conditions.
 * It includes information about regulation, summary of insurance conditions, and a disclaimer.
 * 
 * @component
 * @example
 * return (
 *   <ChubbScreenTerms />
 * )
 */
const ChubbScreenTerms = () => {
    return (
        <div className={style.mainContainer}>
            Regulado y supervisado por la Superintendencia de Seguros y Reaseguros
            de Panamá. Este seguro es ofrecido por Chubb Seguros Panamá, S.A. Lo
            descrito es un resumen de las condiciones del seguro por lo que no debe
            ser interpretado como una representación de los términos, condiciones y
            exclusiones de la póliza. Por favor referirse a la póliza para conocer
            los términos y condiciones aplicables. CMF no es ni será responsable por
            cualquier reclamación o cualquier asunto relacionado o derivado del
            seguro Renta Segura.
        </div>
    )
}

export default ChubbScreenTerms;