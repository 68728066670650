import {
  sendCallService,
  sendSmsService,
  verifySmsService,
} from 'src/Data/Client';
import {STATUS_SIGNATURES} from 'src/utils/Constants';
import {openNotificationWithIcon} from 'src/utils/Functions';
import {logsOnboarding} from './requestCard.actions';

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const sendSmsEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await sendSmsService(payload);
      if (res.data.status === STATUS_SIGNATURES.AwaitingConfirmation) {
        // dispatch({
        //   type: UPDATE_SIGNATURE_REQUEST,
        //   payload: res.data.requestId,
        // })
      } else {
        // ConsoleHelper('error', 'No se ha enviado la solicitud de firma')
      }
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', error);
      await logsOnboarding(
        onboardingId,
        origin,
        'ERROR_SEND_SMS_EID',
        e?.response?.data?.message,
        e,
        false,
      );
      // dispatch({
      //   type: DIALOG_MESSAGE,
      //   dialogType: DIALOG_TYPES.ERROR,
      //   message: error
      //     ? `Sistema biométrico, ${error}`
      //     : 'Sistema biométrico, no se pudo enviar mensaje de texto',
      // })
    }
  };
};

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const verifySmsEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await verifySmsService(payload);
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', error);
      logsOnboarding(
        onboardingId,
        origin,
        'ERROR_VERIFY_SMS_EID',
        e?.response?.data?.message,
        error,
        false,
      );
    }
  };
};

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const sendCallEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await sendCallService(payload);
      if (res.data.status === STATUS_SIGNATURES.AwaitingConfirmation) {
        // dispatch({
        //   type: UPDATE_SIGNATURE_REQUEST,
        //   payload: res.data.requestId,
        // })
      } else {
        // ConsoleHelper('error', 'No se ha enviado la solicitud de firma')
      }
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', error);
      logsOnboarding(
        onboardingId,
        origin,
        'ERROR_SEND_CALL_EID',
        e?.response?.data?.message,
        error,
        false,
      );
      // dispatch({
      //   type: DIALOG_MESSAGE,
      //   dialogType: DIALOG_TYPES.ERROR,
      //   message: error
      //     ? `Sistema biométrico, ${error}`
      //     : 'Sistema biométrico, no se pudo enviar mensaje de texto',
      // })
    }
  };
};

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const verifyCallEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await verifyCallEid(payload);
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', error);
      logsOnboarding(
        onboardingId,
        origin,
        'ERROR_VERIFY_CALL_EID',
        error?.response?.data,
        error,
        false,
      );
    }
  };
};
