import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const StyledText = styled('div')(({customStyle, type}) => ({
  ...customStyle,
  fontFamily: 'inherit',
  whiteSpace: type === 'singleline' ? 'nowrap' : 'normal',
  overflow: type === 'singleline' ? 'hidden' : 'visible',
  textOverflow: type === 'singleline' ? 'ellipsis' : 'clip',
}));

function UniversalText({
  textType = 'p',
  multiline = true,
  minSize,
  maxSize,
  text,
  color = 'black',
  fontFamily = 'Arial, sans-serif',
  textAlign = 'left',
  style,
}) {
  const [fontSize, setFontSize] = useState(minSize);
  console.log(style, 'style');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let calculatedFontSize;

      if (screenWidth <= 300) {
        calculatedFontSize = minSize;
      } else if (screenWidth >= 1920) {
        calculatedFontSize = maxSize;
      } else {
        const ratio = (screenWidth - 300) / (1920 - 300);
        calculatedFontSize = minSize + ratio * (maxSize - minSize);
      }

      setFontSize(calculatedFontSize);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [minSize, maxSize]);

  const fontSizeInEm = fontSize ? fontSize / 18 : 1;

  const TextComponent = textType || 'p';

  return (
    <StyledText
      as={TextComponent}
      customStyle={{
        ...style,
        fontSize: `${fontSizeInEm}em`,
        color: color,
        fontFamily: fontFamily,
        textAlign: textAlign,
      }}
      type={multiline ? 'multiline' : 'singleline'}>
      {text}
    </StyledText>
  );
}

UniversalText.propTypes = {
  textType: PropTypes.string,
  multiline: PropTypes.bool,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  textAlign: PropTypes.string,
  style: PropTypes.object,
};

export default UniversalText;
