export const SCREENS = {
  Welcome: 1,
  Recommendation: 2,
  VerifyMailPhone: 3,
  ScanID: 4,
  // IdentityConfirmed: 5,
  ApcTerms: 5,
  ActividadLaboral: 6,
  ChubbScreen: 7,
  CreditApproved: 8,
  ReadAndSignPDF: 9,
  ErrorScreen: 10,
  FinancialError: 11,
  RequestInRevision: 12,
  ApprovedRequest: 13,
  ApprovedInsurance: 14,
  ProductExist: 15,
  IdentityMissingFields: 16,
  PrepagoProductExist: 17,
  PrepagoRequestInRevision: 18,
  PrepagoRedirectApp: 19,
  ReadPdfPrepago: 20,
  PrepagoProductStop: 21,
};

export default SCREENS;
