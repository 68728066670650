import { styled } from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SeccionTitle from 'src/components/Accions/AccionesRapidas';
import { getDetailsAccount } from 'src/store/actions/MovimientoAction';
import { BreakPointTheme } from 'src/theme/breakpoints';
import formats from 'src/utils/formats';
import {
  TransactionTypeDecorator,
  formatearFecha,
  getTransactionTypeDescription,
  ocultarNumero,
} from 'src/utils/Functions';
import { ContainerData, ContainerMaterial } from '../Pages/ContainerHome';
import Movimientos from './Movimientos';
import TarjetasDatos from './TarjetasDatos';

const TableMain = styled(Table)(({imageUrl, theme}) => ({
  borderRadius: '0 0 20px 20px',
  cursor: 'pointer',
  width: '100%',
  background: '#FFFFFF',
}));
function createData(details, data) {
  return {details, data};
}
function getEntryAndCommerce(text = '') {
  //
  const split = text?.split('/');
  return {
    entry: split[0] || '',
    commerce: split[1] || '',
  };
}

const ComponentMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {listCustomerProducts, selectedProduct} = useSelector(
    state => state.ActiveProductReducer,
  );
  const [backMov, setBackMov] = useState(1);
  const [clean, setClean] = useState(false);
  const [rendering, setRendering] = useState(true);
  const [mainPage, setMainPage] = useState(true);
  const [detailsMovement, setDetailsMovent] = useState(null);
  const [value, setValue] = React.useState(''); // State to hold the dropdown value
  const [imageBackground, setImageBackground] = useState(null);
  useEffect(() => {
    setValue(
      selectedProduct?.customerProductId ??
        listCustomerProducts[0].customerProductId,
    );
  }, [selectedProduct, listCustomerProducts]);

  useEffect(() => {
    if (listCustomerProducts.length > 0) {
      const firstCardImage =
        listCustomerProducts[0].customerAccountCards[0].cardImage;
      setImageBackground(firstCardImage);
    }
  }, [listCustomerProducts, imageBackground]);

  useEffect(() => {
    setBackMov(1);
  }, []);

  const handleDateChange = newDate => {
    setRendering(false);
  };

  const backHome = () => {
    navigate('/home');
  };

  const backHomeDetails = () => {
    setRendering(false);
    setMainPage(true);
  };

  const rows = [
    createData(
      'Monto Total',
      detailsMovement &&
        `${
          TransactionTypeDecorator[detailsMovement[0]?.TipoMov]
        } ${formats.currency(detailsMovement[0]?.MtoTra)}`,
    ),
    createData(
      'Comercio',
      detailsMovement &&
        getEntryAndCommerce(detailsMovement[0]?.Descripcion).commerce.trim(),
    ),
    createData(
      'Rubro',
      detailsMovement &&
        getTransactionTypeDescription(detailsMovement[0]?.Transaccion),
    ),
    createData(
      'Fecha',
      detailsMovement && detailsMovement.length > 0 && detailsMovement[0]
        ? formatearFecha(detailsMovement[0]?.FechaOrigen, 'MM/DD/YYYY')
        : null,
    ),
    createData(
      'Código de Autorización',
      detailsMovement && detailsMovement[0]?.NoAutorizacion.trim() !== ''
        ? detailsMovement[0]?.NoAutorizacion
        : 'N/A',
    ),
  ];

  const myData = as => {
    setDetailsMovent(new Array(as));
    setMainPage(false);
  };

  const handleChange = event => {
    setValue(event.target.value);
    setClean(true);
    const data = listCustomerProducts.find(
      e => e.customerProductId === event.target.value,
    );
    if (data) {
      dispatch({
        type: 'SELECTED_PRODUCT',
        payload: data,
      });
      dispatch(
        getDetailsAccount(data?.productAccount, data?.customerProductId),
      );
    }
  };

  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData
        style={{
          marginBottom: '3rem',
        }}>
        {mainPage ? (
          <>
            <Box
              onClick={backHome}
              sx={{
                display: 'flex',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '40px',
                background: 'var(--specific-light-background-white, #fff)',
                width: '3rem',
                margin: '5px 0px',
                cursor: 'pointer',
              }}>
              <ArrowBackIcon />
            </Box>

            <SeccionTitle title="Estado de cuenta" />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
                marginBottom: 2,
                [BreakPointTheme.breakpoints.up('md')]: {
                  width: '213px',
                },
              }}>
              <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                sx={{
                  height: 55,
                  [BreakPointTheme.breakpoints.down('md')]: {
                    width: '70%',
                  },
                }}
                inputProps={{'aria-label': 'Without label'}}>
                {listCustomerProducts.map(item => (
                  <MenuItem key={item.id} value={item.customerProductId}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Box sx={{marginLeft: '9px', marginRight: 2}}>
                        <img
                          src={item.customerAccountCards[0]?.cardImage}
                          alt={item?.description}
                          style={{width: '50px', height: '32px'}}
                        />
                      </Box>
                      <Box>
                        <Typography>
                          {ocultarNumero(
                            item.customerAccountCards[0]?.lastFourDigitCard,
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Movimientos
              path={false}
              all={true}
              onClick={handleDateChange}
              myData={myData}
              rendering={rendering}
            />
          </>
        ) : detailsMovement ? (
          <>
            <Box
              sx={{
                display: 'flex',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '40px',
                background: 'var(--specific-light-background-white, #fff)',
                width: '3rem',
                margin: '5px 0px',
              }}>
              <ArrowBackIcon onClick={backHomeDetails} />
            </Box>
            <br />
            <Typography variant="H5">Datos de la transacción</Typography>
            <br />
            <TarjetasDatos data={detailsMovement} />
            <TableMain aria-label="simple table">
              <TableBody>
                {rows.map(row => (
                  <TableRow
                    key={row.name}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell align="left" style={{color: '#101828A3'}}>
                      <Typography variant="H4">{row.details}</Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: '#026E18',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '18px',
                      }}>
                      {row.data}
                    </TableCell>
                  </TableRow>
                  // <></>
                ))}
              </TableBody>
            </TableMain>
          </>
        ) : (
          <Box component={Paper}>
            <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
            </Paper>
          </Box>
        )}
      </ContainerData>
    </ContainerMaterial>
  );
};

export default ComponentMain;
