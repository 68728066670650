import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { includeSafe } from 'src/store/actions/KO.Actions'
import { ORIGIN } from 'src/utils/Constants'
import { setStep } from 'src/store/actions/Steps.Action'
import { SCREENS } from 'src/containers/OnboardingCredito/screens'
import style from './chubbscreen.module.css'
import ChubbScreenHeader from './ChubbScreenHeader'
import ChubbScreenBenefits from './ChubbScreenBenefits'
import ChubbScreenPaymentInfo from './ChubbScreenPaymentInfo'
import ChubbScreenButtons from './ChubbScreenButtons'
import ChubbScreenTerms from './ChubbScreenTerms'

const ChubbScreen = props => {
  const dispatch = useDispatch()
  const { Matrix } = useSelector(state => state.KOReducer)
  const { form, origin, configGlobal, levelRisk } = useSelector(
    state => state.RequestCardReducer,
  )
  const isPep = configGlobal ? true : form.isPolitician === 1 ? true : false
  const isCrs = form.relationPep === 1 ? true : false
  
  const nextStep = () => {
    if (origin === ORIGIN.PREAPROBADO) {
      if (!isPep && !isCrs && levelRisk !== 'ALTO') {
        /* continue the sign document */
        dispatch(setStep(SCREENS.CreditApproved))
        props.goToStep(SCREENS.CreditApproved)
      } else {
        /* go to revision */
        dispatch(setStep(SCREENS.RequestInRevision))
        props.goToStep(SCREENS.RequestInRevision)
      }
    } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (
        Matrix.result === 'APROBADO' &&
        Matrix.isPep === false &&
        !isPep &&
        !isCrs &&
        levelRisk !== 'ALTO'
      ) {
        /* continue the sign document */
        dispatch(setStep(SCREENS.CreditApproved))
        props.goToStep(SCREENS.CreditApproved)
      } else if (
        (Matrix.result === 'APROBADO' && Matrix.isPep === true) ||
        isPep ||
        isCrs ||
        levelRisk === 'ALTO'
      ) {
        /* go to revision */
        dispatch(setStep(SCREENS.RequestInRevision))
        props.goToStep(SCREENS.RequestInRevision)
      }
    }
  }
  const btnClick = () => {
    dispatch(includeSafe(4, onSuccess.bind(this)))
  }
  const onSuccess = success => {
    if (origin === ORIGIN.PREAPROBADO) {
      if (success) {
        if (!isPep && !isCrs && levelRisk !== 'ALTO') {
          /* continue the sign document */
          dispatch(setStep(SCREENS.CreditApproved))
          props.goToStep(SCREENS.CreditApproved)
        } else {
          /* go to revision */
          dispatch(setStep(SCREENS.RequestInRevision))
          props.goToStep(SCREENS.RequestInRevision)
        }
      }
    } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (success) {
        if (
          Matrix.result === 'APROBADO' &&
          Matrix.isPep === false &&
          !isPep &&
          !isCrs &&
          levelRisk !== 'ALTO'
        ) {
          /* continue the sign document */
          dispatch(setStep(SCREENS.CreditApproved))
          props.goToStep(SCREENS.CreditApproved)
        } else if (
          (Matrix.result === 'APROBADO' && Matrix.isPep === true) ||
          isPep ||
          isCrs ||
          levelRisk === 'ALTO'
        ) {
          /* go to revision */
          dispatch(setStep(SCREENS.RequestInRevision))
          props.goToStep(SCREENS.RequestInRevision)
        }
      }
    }
  }
  return (
    <div className={style.mainContainer}>
      <ChubbScreenHeader />
      <ChubbScreenBenefits />
      <ChubbScreenPaymentInfo />
      <ChubbScreenButtons onCancel={nextStep} onNext={btnClick} />
      <ChubbScreenTerms />
    </div>
  )
}

export default ChubbScreen
