import { styled } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { Cmf_Base } from 'src/Data/Api';
import CustomStyledBox from 'src/components/Box/BoxCustom';
import { ButtonCheckbox } from 'src/components/Button/ButtonCheckbox';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  getTransactionTypeDescription,
  TransactionTypeDecorator,
} from 'src/utils/Functions';

const StyleBox = styled('div')(({type}) => ({
  ...(type === 'Button' && {
    [BreakPointTheme.breakpoints.up('xxs')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  }),
}));

const ButtonPay = styled(Button)(({theme}) => ({
  borderRadius: '50px',
  height: '3.5rem',
  color: theme.palette.common.white,
  fontFamily: 'Red Hat Text',
  fontWeight: 1,
  fontSize: '1rem',
  margin: '0 8px',
  textTransform: 'none',
  [BreakPointTheme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '48px',
    height: '48px',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '100px',
  },
  [BreakPointTheme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const DownloadMovements = ({texto, types, dates, origin, data}) => {
  const [open, setOpen] = useState(false);
  const [loadings, setLoading] = useState(false);
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const [actualStep, setActualStep] = useState(0);
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);
  const [selectedOption, setSelectedOption] = useState(null);
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const handleCheckboxChange = index => {
    setSelectedOption(index);
  };
  const cleanCloseModal = () => {
    setOpen(false);
    setSelectedOption(null);
    setLoading(false);
    setActualStep(0);
  };
  function ExcelReportDownload() {
    const downloadExcel = async () => {
      const dataPrueba = data ?? '';
      let option = {};
      let dataTable = [];
      if (dataPrueba) {
        for (let i in dataPrueba) {
          if (Array.isArray(dataPrueba) && dataPrueba.length > 0) {
            let obj = {
              transaccion: moment(dataPrueba[i].FechaOrigen).format(
                'MM/DD/YYYY',
              ),
              comercio: dataPrueba[i].Descripcion,
              code: getTransactionTypeDescription(dataPrueba[i].Transaccion),
              monto: [
                `${
                  TransactionTypeDecorator[dataPrueba[i]?.TipoMov]
                } ${dataPrueba[i]?.MtoTra?.toFixed(2)}`,
              ],
              account: selectedProduct?.decryptedProductAccount,
              nameEnte: selectedProduct?.fullName,
              name: dataCurrent?.Nombre,
            };
            dataTable.push(obj);
          }
        }
      }
      option.fileName = 'Reporte de movimientos';
      option.datas = [
        {
          sheetData: dataTable,
          sheetName: 'sheet',
          sheetFilter: [
            'transaccion',
            'comercio',
            'code',
            'monto',
            'account',
            'nameEnte',
            'name',
          ],
          sheetHeader: [
            'F. Transacción',
            'Comercio',
            'Código de transacción',
            'Monto',
            'Cuenta',
            'Nombre del ente',
            'Nombre de tarjeta',
          ],
          columnWidths: [10, 20, 10, 10, 10, 10, 20],
        },
      ];

      let toExcel = new ExportJsonExcel(option);
      await toExcel.saveExcel();
    };
    downloadExcel();
  }
  /**
   * Downloads a PDF file containing the account status for a given product, year, and month.
   * @async
   * @function
   * @param {number} index - The index of the active product in the list.
   * @param {number} year - The year of the account status to download.
   * @param {number} month - The month of the account status to download.
   * @returns {Promise<void>}
   */
  async function downloadPDF(index, year, month) {
    setLoading(true);
    let data = {
      onboardingId: 0,
      templateName: 'templates/196005/output',
      Origin: selectedProduct.productAccount,
      anyo: year,
      mes: month,
    };
    try {
      const response = await axios.post(
        `${Cmf_Base}api/print/pdf/accountstatus`,
        data,
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
          },
        },
      );

      if (response.status !== 200) {
        throw new Error(
          `Solicitud fallida con código de estado ${response.status}`,
        );
      }

      if (response.data) {
        const base64String = response.data.response;
        const linkSource = `data:application/pdf;base64,${base64String}`;
        const fileName = `Estado de cuenta ${month}-${year}`;

        // Crear un enlace para la descarga
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink); // Agregar el enlace al cuerpo del documento

        // Configurar los atributos del enlace para la descarga
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';

        // Verificar si el navegador soporta la descarga directa mediante el atributo download
        if (typeof downloadLink.download === 'string') {
          // Disparar la descarga
          downloadLink.click();
        } else {
          // Si no es compatible, abrir el PDF en una nueva ventana o pestaña
          window.open(linkSource, '_blank');
        }

        // Limpiar: eliminar el enlace del documento después de la descarga
        document.body.removeChild(downloadLink);
        setOpen(false);
      }
    } catch (error) {
      NotificationManager.error('Ha ocurrido un error al descargar el PDF.');
    } finally {
      setLoading(false);
    }
  }
  const handleDownload = index => {
    if (selectedOption === 0) {
      downloadPDF(0, parseInt(dates.year), parseInt(dates.month));
    }
  };
  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      setActualStep(0);
    }
    setOpen(open);
  };
  const FileFormat = [
    origin
      ? {
          title: 'EXCEL',
          color: true,
        }
      : {
          title: 'PDF',
          color: true,
        },
  ];

  const DownloadScreen = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <Box
            sx={{
              display: 'flex',
              padding: '24px 24px 16px 24px',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              borderBottom: '1px solid #EAECF0',
            }}>
            <Typography variant="titleModal">
              Descarga tu estado de cuenta
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: '28px',
                height: '28px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '8px',
                border: '1.6px solid #D0D5DD',
              }}>
              <CloseIcon
                sx={{
                  width: '16px',
                  height: '16px',
                  flexShrink: 0,
                  cursor: 'pointer',
                }}
                onClick={cleanCloseModal}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: '16px 24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              alignSelf: 'stretch',
            }}>
            <Typography variant="description">
              Este documento incluirá tus transacciones actualmente visibles.
            </Typography>
            <Typography variant="subtitle1">Formato de archivo</Typography>
            <Box
              sx={{
                display: 'flex',
                padding: '0px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
                alignSelf: 'stretch',
                width: '100%',
              }}>
              {FileFormat.map((option, index) => (
                <ButtonCheckbox
                  key={index}
                  icon={false}
                  title={option.title}
                  isSelected={selectedOption === index}
                  step={true}
                  color={option.color}
                  onCheckboxChange={() => handleCheckboxChange(index)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              padding: '16px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
            }}>
            <LoadingButton
              type="button"
              variant="contained"
              disabled={selectedOption === null}
              loading={loadings}
              sx={{
                display: 'flex',
                height: '56px',
                padding: '0px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                borderRadius: '100px',
                fontWeight: '500',
              }}
              onClick={origin ? ExcelReportDownload : handleDownload}>
              <Typography variant="buttonStyle">Descargar</Typography>
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </CustomStyledBox>
  );
  const StepperToPaid = [
    {
      screen: 'DownloadScreen',
      title: 'Descarga tus movimientos',
      component: DownloadScreen,
    },
  ];

  return (
    <StyleBox type={types}>
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <ButtonPay
            type="button"
            variant="contained"
            disabled={
              selectedProduct?.isCorporate
                ? !data.length > 0
                : dates?.month === 0 || dates?.year === 0
            }
            loading={loadings}
            sx={{
              fontWeight: '500 !important',
              [BreakPointTheme.breakpoints.down('sm')]: {
                '& .MuiButton-startIcon': {margin: 'auto'},
              },
            }}
            startIcon={
              <VerticalAlignBottomIcon
                sx={{
                  width: '22px',
                  height: '22px',
                  margin: 0,
                }}
              />
            }
            onClick={() => {
              setOpen(true);
            }}>
            {texto}
          </ButtonPay>
          <SwipeableDrawer
            sx={{
              '& .MuiDrawer-paper': {
                boxShadow: 'none',
                backgroundColor: '#00000000',
              },
            }}
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}>
            {StepperToPaid[actualStep]['component'](anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </StyleBox>
  );
};

DownloadMovements.propTypes = {
  data: PropTypes.shape({
    length: PropTypes.any,
  }),
  dates: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  origin: PropTypes.any,
  texto: PropTypes.any,
  types: PropTypes.any,
};
export default DownloadMovements;
